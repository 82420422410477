@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

// Colors
$yellow: #ffd306;
$yellow-dark: #febf1f;
$yellow-chat: #fff567;
$dark: #080708;
$black: #000;
$white: #fff;
$light-gray: #f2f2f2;
$text-gray: #707070;
$border-gray: #c5c5c5;
$gray1: #666666;

// Defaults
.btn-yellow,
.btn-black,
.btn-gray {
  background: $yellow;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: $dark;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 40px 0 40px 0;
  &:hover {
    background: $yellow-dark;
  }
}
h3 {
  font-size: 18px;
}
.btn-inactive {
  background: #e4e4e4;
  color: #8b8b8b;
  pointer-events: none;
  &:hover {
    background: #e4e4e4;
  }
}

.btn-white {
  background: $white;
  border-radius: 30px;
  border: 1px solid $dark;
  color: $dark;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;
}

.pointer-event-unset {
  pointer-events: unset !important;
}

.btn-black {
  background: $black;
  color: $white;
  &:hover {
    background: $black;
    color: $yellow;
  }
}

.btn-gray {
  background: #b7b7b7;
  font-size: 14px;
  color: $dark;
  &:hover {
    background: #b7b7b7;
    color: $dark;
  }
}

button:focus,
.btn:focus,
input:focus,
.form-control:focus,
input:focus,
.form-check-input:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: none;
}

.text-success {
  color: #11af2b !important;
}

.text-danger {
  color: #ef5d60 !important;
}

.text-yellow {
  color: $yellow !important;
}

.z9 {
  position: relative;
  z-index: 9 !important;
}

.overflow-unset {
  overflow: unset !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.img-white {
  filter: brightness(0) invert(1);
  -moz-filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.dark-txt {
  color: $dark !important;
}

.minh-unset {
  min-height: unset !important;
}

.with-ico-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-case-unset {
  text-transform: unset !important;
}

body {
  background: $light-gray;
  font: 16px 'Roboto', sans-serif;
  color: $dark;
}

// Splash Style
.splash-wrap {
  background: $yellow;
  height: 100vh;
  display: flex;
  .container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    .free-badge {
      position: absolute;
      bottom: 40px;
      right: 0;
      width: 200px;
      &.small {
        width: 130px;
      }
    }
    h3 {
      font-size: 28px;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 30px;
    }
    p {
      margin: 15px 0;
    }
  }
  @media only screen and (min-width: 767px) {
    .container {
      width: 600px;
    }
  }
}

// Header Style
header {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  &:before {
    content: '';
    width: 100%;
    height: 20px;
    background: $white;
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: -9;
    transform: skewY(-1deg);
    -moz-transform: skewY(-1deg);
    -webkit-transform: skewY(-1deg);
  }
  .btn {
    display: flex;
    align-items: center;
  }
  &.row {
    flex-wrap: unset;
    margin: 0;
    * {
      width: auto;
    }
    .search-form {
      width: calc(100% - 90px);
      padding-left: 5px;
      padding-right: 5px;
      .form-control {
        width: 100%;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.18);
        font-size: 14px;
        padding: 8px 0;
      }
    }
  }
}

// Content Section Style
.content-sec {
  margin-top: 60px;
  padding-bottom: 100px;
  @media only screen and (min-width: 768px) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Top Menu Style
.topmenu-wrap {
  position: relative;
  padding: 0;
  .top-menu {
    background: $dark;
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-top: 20px;
    padding-bottom: 15px;
    .top-overflow {
      width: auto;
      flex-wrap: unset;
      display: flex;
      .btn-circle {
        width: 75px;
        margin-left: 15px;
        text-align: center;
        text-decoration: none;
        &:last-child {
          margin-right: 15px;
        }
        .img-wrap {
          width: 100%;
          height: 75px;
          overflow: hidden;
          border-radius: 50%;
          border: 2px solid $yellow;
          display: flex;
          img {
            object-fit: cover;
            border: 3px solid $dark;
            height: 100%;
            width: 100%;
          }
        }
        p {
          font-size: 11px;
          color: $white;
          font-weight: 400;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
    }
  }
}

// Map Style
.map-wrap {
  padding: 0;
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

// Skew Snippet Style
.skew-snippet-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 40px 0;
  .skew-snip {
    width: 50%;
    position: relative;
    .cont {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 25px 20px;
      .categories-img {
        border-width: 5px;
        border-radius: 50%;
        border-style: solid;
        box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.3);
        // box-shadow: inset 0px 1px 5px 5px rgba(0, 0, 0, 0.3);
      }
      .ico {
        width: 60px;

        &.big {
          width: 91px;
          margin-top: -23px;
        }
      }
      .top-height {
        margin-top: 0px !important;
      }
      h6 {
        text-shadow: 5px 5px 7px black !important;
        color: $white;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        text-shadow: 0 0 6px $black;
        margin-top: 15px !important;
        margin-left: 20px !important;
        margin-bottom: 10px;
        & + h6 {
          text-indent: 18px;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .skew-snip {
      .cont {
        padding: 40px;
        img {
          width: 70px;
        }
        h6 {
          margin-top: 0;
          font-size: 18px !important;
        }
      }
    }
  }
}

// Product Style
.product-wrap {
  .title-wrap {
    background: url('../images/yellow-title-bg.svg') no-repeat 0 bottom;
    display: flex;
    align-items: center;
    margin: 10px;
    .ico {
      width: 25px;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      color: $dark;
      margin-left: 10px;
      width: calc(100% - 32px);
    }
  }
  .product-list {
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 15px;
    .product-overflow {
      width: auto;
      flex-wrap: unset;
      display: flex;
    }
    .prod-snip {
      background: #fff;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
      display: inline-block;
      width: 310px;
      margin-right: 15px;
      .img-wrap {
        width: 100%;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        &.shop-img {
          &:after {
            display: none;
          }
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        .status {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 9;
          span {
            color: #fff;
            font-size: 15px;
            font-weight: bolder;
            text-transform: uppercase;
            position: absolute;
            left: 15px;
            top: 4px;
          }
          &.start {
            span {
              color: $dark;
              left: 4px;
              top: 3px;
            }
            .ico {
              width: 26px;
              object-fit: none;
            }
          }
          &.end {
            .img-white {
              filter: brightness(0) invert(1);
              -moz-filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            }
            span {
              color: $dark;
            }
          }
        }
        .img-count {
          position: absolute;
          left: 10px;
          bottom: 10px;
          z-index: 9;
          display: flex;
          align-items: center;
          color: #c5c5c5;
          font-size: 12px;
          img {
            width: 16px;
            margin-right: 3px;
          }
        }
        .alert-18 {
          background: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(35px);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            object-fit: none;
            width: auto;
            max-width: 100%;
            height: auto;
          }
        }
      }
      .cont {
        display: inline-block;
        width: 100%;
        h4 {
          font-size: 16px;
          color: $dark;
          font-weight: 500;
          margin: 0;
          padding: 10px;
        }
        .btm-row {
          margin: 0px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px;
          font-size: 14px;
          color: #666666;
        }
        .d-flex {
          width: 100%;

          padding: 0 10px;
          // flex-wrap: wrap;
          &.start {
            margin-top: 0px;
            font-size: 13px;
            color: $dark;
          }
          .ico-cont {
            width: 50%;
            font-size: 14px;
            color: #666666;
            display: flex;
            align-items: center;
            font-weight: bold !important;
            img {
              width: 18px;
              margin-right: 5px;
              position: relative;
              top: -1px;
            }
            .icon-size {
              width: 24px !important;
            }
          }
        }
        .btm-sec {
          border-top: 1px solid #c5c5c5;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          margin-top: 10px;
          .link {
            width: 33.33%;
            display: flex;
            align-items: center;
            flex-flow: column;
            text-align: center;
            font-size: 10px;
            color: $dark;
            font-weight: 500;
            text-decoration: none;
            padding: 10px 0;
            text-transform: uppercase;
            &.inactive {
              opacity: 0.16;
            }
            small {
              font-size: 8px;
            }
            img {
              height: 19px;
              margin-bottom: 8px;
            }
            &.yellow-ellipse {
              background: url('../images/yellow-ellipse.svg') no-repeat 54% 7px;
            }
          }
          .candidate-snippet {
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            margin-bottom: 0;
            padding: 5px;
            align-items: center;
            width: 100%;
            .user-img {
              width: 45px;
              height: 45px;
            }
            .avatar-cont {
              width: calc(100% - 45px);
              .btn-empty {
                width: 95px;
              }
              .vote-progress {
                margin-top: -4px;
              }
            }
          }
        }
      }
      &.business {
        width: 330px;
        .img-wrap {
          height: 260px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: -65px;
            left: -34px;
            background: #fff;
            height: 130px;
            width: 143%;
            transform: rotate(14deg);
            -moz-transform: rotate(14deg);
            -webkit-transform: rotate(14deg);
          }
        }
        .cont {
          margin-top: -140px;
          position: relative;
          z-index: 9;
          padding: 15px;
          top: 0;
          .logo-sec {
            width: 80px;
            height: 80px;
            background: $dark;
            border: 2px solid $dark;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            img {
              // object-fit: contain;
              object-fit: cover;
              height: 77px;
              width: 80px;
              border-radius: 50%;
              overflow: hidden;
              border: 4px solid $white;
            }
          }
          h4 {
            font-size: 18px;
            text-transform: uppercase;
            color: $dark;
            margin-top: 22px;
            padding: 0;
            margin-bottom: 5px;
          }
          .rating-sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rating {
              display: flex;
              align-items: center;
              color: #707070;
              span {
                font-size: 14px;
                margin-left: 3px;
                position: relative;
                top: 1px;
              }
              img {
                width: 18px;
                &.gray {
                  filter: brightness(0);
                  -moz-filter: brightness(0);
                  -webkit-filter: brightness(0);
                  opacity: 0.4;
                }
              }
            }
            .share {
              display: flex;
              align-items: center;
              .link {
                display: inline-block;
                width: 30px;
                margin-left: 12px;
                text-decoration: none;
                position: relative;
                &.yellow-ellipse:after {
                  content: '';
                  background: url('../images/yellow-ellipse.svg') no-repeat 0 0;
                  position: absolute;
                  right: -10px;
                  top: 0px;
                  width: 30px;
                  height: 30px;
                  background-size: 100%;
                  z-index: -9;
                }
              }
            }
          }
          .motto {
            display: inline-block;
            margin-top: 10px;
            h5 {
              font-size: 16px;
              font-weight: 500;
              color: $text-gray;
            }
            p {
              font-size: 12px;
              margin-bottom: 0;
              width: 230px;
              height: 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: $text-gray;
              &.h75-p {
                height: 75px;
              }
            }
          }
        }
        @media only screen and (min-width: 768px) {
          width: 310px;
        }
      }
      &.business-general {
        width: 100%;
        margin-top: 20px;
        .img-wrap {
          height: 260px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: -65px;
            left: -34px;
            background: #fff;
            height: 130px;
            width: 143%;
            transform: rotate(14deg);
            -moz-transform: rotate(14deg);
            -webkit-transform: rotate(14deg);
          }
        }
        .cont {
          margin-top: -155px;
          position: relative;
          z-index: 9;
          padding: 15px;
          top: 0;
          .logo-sec {
            width: 80px;
            height: 80px;
            background: $dark;
            border: 2px solid $dark;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            img {
              object-fit: contain;
              border-radius: 50%;
              overflow: hidden;
              border: 4px solid $white;
            }
          }
          h4 {
            font-size: 18px;
            color: $dark;
            margin-top: 22px;
            padding: 0;
            margin-bottom: 5px;
          }
          .rating-sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rating {
              display: flex;
              align-items: center;
              color: #707070;
              span {
                font-size: 14px;
                margin-left: 3px;
                position: relative;
                top: 1px;
              }
              img {
                width: 18px;
                &.gray {
                  filter: brightness(0);
                  -moz-filter: brightness(0);
                  -webkit-filter: brightness(0);
                  opacity: 0.4;
                }
              }
            }
            .share {
              display: flex;
              align-items: center;
              .link {
                display: inline-block;
                width: 28px;
                margin-left: 12px;
                text-decoration: none;
                position: relative;
                &.yellow-ellipse:after {
                  content: '';
                  background: url('../images/yellow-ellipse.svg') no-repeat 0 0;
                  position: absolute;
                  right: -10px;
                  top: 0px;
                  width: 30px;
                  height: 30px;
                  background-size: 100%;
                  z-index: -9;
                }
              }
            }
          }
          .motto {
            display: inline-block;
            margin-top: 10px;
            h5 {
              font-size: 16px;
              font-weight: 500;
              color: $text-gray;
            }
            p {
              font-size: 12px;
              margin-bottom: 0;
              height: 16px;
              overflow: hidden;
              color: $text-gray;
              &.h75-p {
                height: 75px;
              }
            }
          }
        }
        @media only screen and (min-width: 768px) {
          width: 310px;
        }
      }
    }
  }
  .text-link {
    color: $dark;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  .skew-snippet-wrap {
    margin-top: 20px;
    .w-50 {
      padding-left: 3px;
      padding-right: 3px;
    }
    .skew-snip {
      width: 100%;
      margin-bottom: 5px;
      .cont {
        h6 {
          margin: 0;
          padding-left: 8px;
          font-size: 14px;
          font-weight: bold;
        }
        .ico {
          width: 75px;
        }
      }
    }
  }
  &.overlap {
    position: absolute;
    left: 0;
    z-index: 100;
    // bottom: 40px;
    .product-list {
      padding-bottom: 0;
    }
  }
  .history-count {
    font-size: 12px;
    color: #080708;
    row-gap: 5px;
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    span {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// Footer Style
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .footer-btm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: $white;
    .ftr-btn {
      width: calc(50% - 35px);
      display: flex;
      .link {
        width: 50%;
        padding: 8px 5px;
        display: flex;
        align-items: center;
        flex-flow: column;
        text-decoration: none;
        position: relative;
        &.active {
          background: $yellow;
          z-index: 99;
          &.fav:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background: $yellow;
            width: 145%;
            height: 100%;
            z-index: -9;
          }
        }
        .ico-blk {
          height: 30px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 3px;
          img {
            max-height: 100%;
          }
        }
        p {
          font-size: 9px;
          color: $dark;
          margin-bottom: 0;
        }
        .count {
          position: absolute;
          background: rgb(6, 216, 30);
          background: -moz-linear-gradient(-45deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
          background: -webkit-linear-gradient(-45deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
          background: linear-gradient(135deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06d81e', endColorstr='#27b43d',GradientType=1 );
          color: $white;
          width: 20px;
          height: 20px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          top: 18px;
          right: 16px;
        }
      }
    }
    .btn-scan {
      position: absolute;
      background: $dark;
      left: calc(50% - 35px);
      bottom: 0;
      width: 70px;
      height: 70px;
      padding: 4px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 10px $black;
      -moz-box-shadow: 0 0 10px $black;
      -webkit-box-shadow: 0 0 10px $black;
      z-index: 999;
      span {
        width: 100%;
        height: 100%;
        border: 2px solid $yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        padding: 5px;
        border-radius: 50%;
        color: $white;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        img {
          width: 25px;
          margin: 3px 0;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      .ftr-btn {
        .link {
          p {
            font-size: 14px;
          }
        }
        .btn-scan {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
.ftr-top {
  background: rgba(183, 183, 183, 0.5);

  display: flex;
  align-items: center;
  .fun-ico {
    width: 20px;
    opacity: 0.5;
    margin-right: 5px;
  }
  p {
    font-size: 11px;
    color: $dark;
    opacity: 0.4;
    margin-bottom: 0;
  }
  .btn {
    background: url('../images/login-btm-bg.svg') no-repeat 0 0;
    background-size: cover;
    width: 124px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: -16px;
  }
  @media only screen and (min-width: 768px) {
    .fun-ico {
      width: 30px;
    }
    p {
      font-size: 15px;
    }
    .btn {
      width: 198px;
      height: 70px;
      top: -26px;
      right: 20px;
    }
  }
  &.dual-btn {
    background: transparent;
    justify-content: center;

    // border-bottom: 1px solid #d8d7d7;
    .btn {
      position: static;
      clip-path: polygon(2% 17%, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(2% 17%, 100% 0, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(2% 17%, 100% 0, 100% 100%, 0% 100%);
      background: $dark;
      border-radius: 0;
      width: 135px;
      height: 24px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      margin: 0 10px;
    }
    &.at-shop-btm {
      padding-bottom: 25px;
      .btn {
        clip-path: polygon(100% 0, 94% 79%, 46% 100%, 0 98%, 0 0);
        -moz-clip-path: polygon(100% 0, 94% 79%, 46% 100%, 0 98%, 0 0);
        -webkit-clip-path: polygon(100% 0, 94% 79%, 46% 100%, 0 98%, 0 0);
        background: rgb(8, 7, 8);
        background: -moz-linear-gradient(-45deg, rgba(8, 7, 8, 1) 0%, rgba(123, 123, 123, 1) 100%);
        background: -webkit-linear-gradient(-45deg, rgba(8, 7, 8, 1) 0%, rgba(123, 123, 123, 1) 100%);
        background: linear-gradient(135deg, rgba(8, 7, 8, 1) 0%, rgba(123, 123, 123, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#080708', endColorstr='#7b7b7b',GradientType=1 );
      }
    }
  }
}

// Map Fullscreen Style
.fullscreen {
  height: 100vh;
  position: relative;
  .map-head {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-back {
      padding: 0;
      width: 35px;
      height: 35px;
      background: $dark;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 10px;
        margin-left: -3px;
      }
      &.btn-filter {
        img {
          width: 18px;
          margin-left: 0;
        }
      }
    }
    .btn-yellow {
      background: url('../images/yellow-btn-bg.svg') no-repeat 0 0;
      background-size: contain;
      border-radius: 0;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      img {
        margin-right: 5px;
      }
      &.black {
        background: url('../images/btn-black-bg.svg') no-repeat 0 0;
        color: #fff;
      }
    }
  }
}

// Filter Sidebar Style Starts
.filter-sidebar {
  position: absolute;
  top: 60px;
  right: 0;
  width: 260px;
  height: auto;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.12s linear;
  -moz-transition: all 0.12s linear;
  -webkit-transition: all 0.12s linear;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  &:before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -9;
  }
  .filter-hdr {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid $dark;
    .head {
      display: flex;
      width: 60%;
      align-items: center;
      .btn {
        padding: 5px 5px 5px 0;
        margin-right: 5px;
      }
      h4 {
        color: $dark;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
    }
    a {
      color: $dark;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      text-decoration: none;
    }
  }
  .filter-cont {
    display: flex;
    width: 100%;
    flex-flow: column;
    padding: 0 20px;
    .by-cate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $dark;
      padding: 15px 0px;
      color: $dark;
      text-decoration: none;
      font-size: 12px;
      text-transform: uppercase;
      img {
        filter: brightness(0);
        -moz-filter: brightness(0);
        -webkit-filter: brightness(0);
        opacity: 0.8;
        width: 8px;
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
    form {
      h5 {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .form-check {
        display: flex;
        align-items: center;
        label {
          font-size: 12px;
        }
        .checked {
          // background-color: $dark;
          border: 5px solid $dark;
        }
        .form-check-input {
          font-size: 20px;
          margin-left: -22px;
          margin-right: 10px;
          margin-top: 0;

          &:focus {
            box-shadow: none;
          }
        }
      }
      .group {
        display: inline-block;
        width: 100%;
        padding: 18px 0 15px;
        border-bottom: 1px solid $dark;
      }
      .range-blk {
        display: inline-block;
        width: 100%;
        position: relative;
        span {
          font-size: 10px;
          position: absolute;
          left: 0;
          top: 0;
          &.right {
            right: 0;
            left: auto;
          }
        }
      }
      input[type='range'] {
        width: 100%;
        margin: 6.1px 0;
        background-color: transparent;
        -webkit-appearance: none;
        &:focus {
          outline: none;
        }
        &::-webkit-slider-runnable-track {
          background: $dark;
          border: 0;
          width: 100%;
          height: 1px;
          cursor: pointer;
        }
        &::-webkit-slider-thumb {
          margin-top: -6.1px;
          width: 13px;
          height: 13px;
          background: $dark;
          border: 0;
          border-radius: 50px;
          cursor: pointer;
          -webkit-appearance: none;
        }
        &:focus::-webkit-slider-runnable-track {
          background: $dark;
        }
        &::-moz-range-track {
          background: $dark;
          border: 0;
          width: 100%;
          height: 1px;
          cursor: pointer;
        }
        &::-moz-range-thumb {
          width: 13px;
          height: 13px;
          background: $dark;
          border: 0;
          border-radius: 50px;
          cursor: pointer;
        }
        &::-ms-track {
          background: transparent;
          border-color: transparent;
          border-width: 7.1px 0;
          color: transparent;
          width: 100%;
          height: 1px;
          cursor: pointer;
        }
        &::-ms-fill-lower {
          background: $dark;
          border: 0;
        }
        &::-ms-fill-upper {
          background: $dark;
          border: 0;
        }
        &::-ms-thumb {
          width: 13px;
          height: 13px;
          background: $dark;
          border: 0;
          border-radius: 50px;
          cursor: pointer;
          margin-top: 0px;
        }
        &:focus::-ms-fill-lower {
          background: $dark;
        }
        &:focus::-ms-fill-upper {
          background: $dark;
        }
      }
      @supports (-ms-ime-align: auto) {
        input[type='range'] {
          margin: 0;
        }
      }
    }
  }
  .filter-ftr {
    display: inline-block;
    border-top: 1px solid $dark;
    padding: 10px 15px;
    width: 100%;
    text-align: right;
    a {
      color: $dark;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

// Modal Style Starts
.modal-backdrop {
  background: rgba(227, 227, 227, 0.85);
  backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  opacity: 1 !important;
}
.bg-blur {
  .modal-dialog {
    margin: 0;
    max-width: 100%;
  }
  .modal-content {
    background: transparent;
    border-radius: 0;
    border: none;
    min-height: 100vh;
  }
}

// Privacy Style Starts
.privacy-wrapper {
  color: $dark;
  padding-bottom: 90px;
  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
  h4 {
    font-size: 16px;
    margin: 15px 0;
    font-weight: bold;
  }
  p {
    font-size: 13px;
    line-height: 18px;
  }
  h6 {
    text-shadow: 0 0 12px #ef5d60;
    -moz-text-shadow: 0 0 12px #ef5d60;
    -webkit-text-shadow: 0 0 12px #ef5d60;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
  }
  .privacy-badge {
    max-width: 240px;
    display: block;
    margin: 0 auto;
  }
  .btn-close-x {
    position: fixed;
    bottom: 0;
    left: 0;
    img {
      width: 55px;
    }
  }
  .cont-hiw {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    .numeric {
      width: 36px;
      height: 36px;
      border: 2px solid #000;
      background: #ffd306;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .cont-rgt {
      width: calc(100% - 36px);
      padding-left: 12px;
      h5 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 5px;
      }
      p {
        margin-bottom: 0;
        &.img-right {
          img {
            margin-top: -3px;
            position: absolute;
            right: 0;
            bottom: auto;
          }
        }
        img {
          margin: 0 5px;
          &.border {
            border: 1px solid #b7b7b7 !important;
            padding: 5px;
          }
        }
      }
    }
  }
  .static-btm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    p {
      font-weight: bold;
    }
    .static {
      position: relative;
      left: 0;
    }
    a {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// Closest Shop Style
.fullscreen {
  &.with-btm {
    height: calc(100vh - 155px);
  }
}

.gps-btn {
  background: $black;
  padding: 5px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-sh {
  position: absolute;
  left: calc(50% - 38px);

  z-index: 10;
  background: $black;
  color: $white !important;
  border-radius: 43px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px 24px;
}

// Item Page Style
.item-snippet {
  position: relative;
  z-index: 9;
  .product-list {
    width: 100%;
    .product-overflow {
      width: 100%;
      .prod-snip {
        margin-right: 0;
        width: 100%;
        .business-name {
          display: flex;
          width: 100%;
          background: $white;
          padding: 15px;
          .logo-sec {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            padding: 0px;
            border: 2px solid $black;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
            img {
              border: 3px solid $white;
              border-radius: 50%;
            }
          }
          .cont-sec {
            width: calc(100% - 45px);
            padding-left: 15px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            h4 {
              font-size: 18px;
              margin-bottom: 0;
              color: $black;
            }
            .rating {
              .ico {
                width: 16px;
                &.gray {
                  filter: brightness(0);
                  -moz-filter: brightness(0);
                  -webkit-filter: brightness(0);
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .img-wrap {
          height: 297px;
        }
        .cont {
          p {
            font-size: 12px;
            line-height: 15px;
            color: $text-gray;
            padding: 0 10px;
            margin-bottom: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 230px;
            overflow: hidden;
          }
          .full-desc {
            padding: 0 10px 5px 5px;
            font-size: 12px;
            color: $text-gray;
            display: flex;
            justify-content: space-between;
            border-bottom: 0px solid $border-gray;
            a {
              font-size: 12px;
              color: $text-gray;
              text-transform: uppercase;
            }
          }
          .start {
            flex-flow: column;
            align-items: center;
            p {
              color: $dark;
              font-size: 18px;
              text-align: center;
              margin-bottom: 5px;
              span {
                width: 100%;
                font-size: 12px;
                display: inline-block;
              }
            }
            .ico-cont {
              width: auto;
              font-weight: 500;
            }
          }
        }
        .shop-carousel {
          .img-wrap {
            align-items: flex-start;
            height: auto;
          }
          .img-cover {
            display: inline-block;
            width: 100%;
          }
        }
        .map-sec {
          overflow-y: scroll;
          display: inline-block;
          width: 100%;
          height: 215px;
          position: relative;
          iframe {
            height: 100%;
          }
          .btn {
            position: absolute;

            z-index: 99;
            font-size: 12px;
          }
          .map-btn {
            width: 50px;
          }
        }
        .shop-detail {
          width: 100%;
          display: inline-block;
          padding: 15px;
          .working-hour-container {
            .working-hour {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-left: 5%;
              span {
                width: 33%;
                color: #707070;
                padding: 2px;
                // text-align: center;
              }
            }
            button {
              width: 100%;
            }
            p {
              font-size: 17px;
              color: $dark;
              margin-bottom: 0;
              margin: 10px;
              width: 100%;
              padding: 12px 0;
              border-top: 1px solid $text-gray;
              text-align: center;
            }
          }
          .p-row {
            display: flex;
            width: 100%;
            align-items: center;
            p {
              font-size: 13px;
              color: $dark;
              margin-bottom: 0;
              margin-left: 10px;
              width: 100%;
              padding: 12px 0;
              border-bottom: 1px solid $text-gray;
              display: flex;
              justify-content: space-between;
              a {
                font-size: 12px;
                font-weight: 500;
                color: $dark;
                text-transform: uppercase;
                text-decoration: none;
              }
            }
          }
          .rating-row {
            display: flex;
            justify-content: center;
            flex-flow: column;
            padding: 20px 0 10px;
            .rate {
              display: flex;
              justify-content: center;
              align-items: center;
              h2 {
                margin-bottom: 0;
                font-size: 45px;
                color: $text-gray;
                font-weight: 400;
              }
              .rating {
                padding-left: 10px;
                text-align: center;
                .gray {
                  filter: brightness(0);
                  -moz-filter: brightness(0);
                  -webkit-filter: brightness(0);
                  opacity: 0.4;
                }
                p {
                  margin-bottom: 0;
                  font-size: 16px;
                  color: $text-gray;
                }
              }
            }
            h6 {
              margin-bottom: 0;
              text-align: center;
              margin-top: 20px;
              a {
                color: $dark;
                text-decoration: none;
                font-size: 12px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
    .border-top {
      border-color: #c5c5c5 !important;
    }
  }
}

// Candidates Style
.candidate-snippet {
  background: $white;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  padding: 7px 15px;
  margin: 10px auto;
  // margin-bottom: 5px;
  // margin: auto;
  display: flex;
  width: 92%;
  position: relative;

  .user-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    img {
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid $yellow;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .avatar-cont {
    display: flex;
    width: calc(100% - 65px);
    padding-left: 10px;
    align-items: center;
    .ac-lft {
      width: calc(100% - 55px);
      display: flex;
      justify-content: flex-start;
      flex-flow: column;
      padding-right: 10px;
    }
    h6 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 2px;
    }
    .vote-count {
      font-size: 12px;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      img {
        width: 22px !important;
      }
    }
    .progress-img {
      position: absolute;
      bottom: -7px;
      width: 100%;
    }
    .progress-p {
      position: absolute;
      top: -15px;
      color: #aeadad;
      font-size: 9px;
      right: 20px;
    }
    .progress-posi {
      position: absolute;
      top: -16px;
      left: 31px;
      color: rgb(253, 213, 25);
      font-size: 12px;
      font-weight: bolder;
    }
    .postion-avatar-span {
      top: -18px !important;
      left: 34px !important;
    }
    .btn-empty {
      width: 55px;
      padding: 0;
      &.inactive {
        opacity: 0.16;
      }
    }
  }
  &.bordered {
    border: 3px solid $dark;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0 0 13px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0 0 13px rgba(0, 0, 0, 0.55);
  }
}

// Winner Sec Style
.winner-sec {
  background: url('../images/winner-bg.png') no-repeat center center;
  background-size: cover;
  min-height: 550px;
  margin-top: -35px;
  margin-bottom: -65px;
  position: relative;
  text-align: center;
  &.mb-minus-max {
    margin-bottom: -130px;
  }
  &.mt-minus-65 {
    margin-top: -65px;
    min-height: 610px;
  }
  &.modal-pop {
    background-color: #e5e5e5;
    margin-top: -62px;
    .confirm {
      font-size: 16px;
      font-weight: 600;
      margin-top: -65px;
      z-index: 99;
      position: relative;
    }
    @media only screen and (max-width: 370px) {
      margin-top: -150px;
      .confirm {
        margin-top: -55px;
      }
    }
  }
  .top-light {
    margin-top: 80px;
  }
  .winner-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $dark;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.23);
    position: absolute;
    top: 190px;
    left: calc(50% - 70px);
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 4px solid $yellow;
    }
  }
  .winner-badge {
    position: relative;
    z-index: 9;
    .badge-cont {
      position: absolute;
      left: calc(50% - 107px);
      width: 215px;
      top: 9%;
      h6 {
        font-size: 16px;
        margin-bottom: 0px;
        color: $dark;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $white;
        margin-bottom: 0;
        text-transform: uppercase;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -moz-text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
      .win-place {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        text-shadow: 0 0 6px black;
        position: absolute;
        left: 4%;
        top: 13%;
      }
    }
    .btn-clip-wrap {
      position: relative;
      top: -50px;
    }
  }
}

// Feedback Wrap Style Starts
.feedback-wrap {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(253, 213, 25, 1) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(253, 213, 25, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(253, 213, 25, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fdd519',GradientType=1 );
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
  min-height: 180px;
  padding: 15px;
  .f-row {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    .fr-head {
      display: flex;
      width: 100%;
      align-items: center;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid $dark;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 2px solid $yellow;
        }
      }
      .avatar-detail {
        display: flex;
        width: calc(100% - 50px);
        padding-left: 10px;
        flex-flow: column;
        h6 {
          margin-bottom: 5px;
        }
        .reply {
          color: $dark;
          font-size: 12px;
          text-decoration: none;
        }
        .rating-sec {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: $dark;
          .rating {
            margin-left: 5px;
            display: flex;
            align-items: center;
            img {
              width: 15px;
              &.gray {
                filter: brightness(0);
                -moz-filter: brightness(0);
                -webkit-filter: brightness(0);
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    p {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 12px;
      color: $dark;
    }
  }
}

// Alert Bubble Style
.alert-bubble {
  position: relative;
  .alert-cont {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    padding: 20% 20vw 3px;
    text-align: center;
    h5 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

// Feedback Style
.shop-img {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.business-tri {
  margin-top: -110px;
  position: relative;
  z-index: 9;
  .tri-bg {
    width: 100%;
  }
  .cont {
    position: absolute;
    top: -30px;
    left: 0;
    padding: 0 30px;
    width: 100%;
    .logo-sec {
      border-radius: 50%;
      overflow: hidden;
      width: 80px;
      height: 80px;
      border: 2px solid $dark;
      display: flex;
      align-items: center;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid $white;
      }
    }
    h4 {
      font-size: 18px;
      margin-top: 15px;
    }
    .share {
      float: right;
      margin-top: 0;
      a {
        text-decoration: none;
        margin-left: 10px;
      }
    }
  }
  .rating-row {
    display: flex;
    width: 100%;
    background: $white;
    padding: 0 25px 35px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    margin-top: -10px;
    .rate {
      display: flex;
      align-items: center;
      h2 {
        margin-bottom: 0;
        font-size: 45px;
        color: $text-gray;
        font-weight: 400;
      }
      .rating {
        text-align: left;
        padding-left: 10px;
        img {
          width: 16px;
          &.gray {
            filter: brightness(0);
            -moz-filter: brightness(0);
            -webkit-filter: brightness(0);
            opacity: 0.4;
          }
        }
        p {
          font-size: 16px;
          color: $text-gray;
          margin-bottom: 0;
        }
      }
    }
  }
}

.tbd-encl {
  .TBD_Card {
    align-items: flex-start;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
    h5 {
      font-size: 16px;
      margin-bottom: 5px;
      width: calc(100% - 120px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// Shop Picture Style
.slider-img {
  padding: 0;
  height: 58vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .blur-bg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(7px);
    -moz-filter: blur(7px);
    -webkit-filter: blur(7px);
  }
  .actual-img {
    object-fit: contain;
    width: 100%;
    position: relative;
    z-index: 99;
  }
  .btn-sec {
    position: absolute;
    right: 20px;
    bottom: 15px;
    z-index: 99;
    a {
      text-decoration: none;
      width: 25px;
      display: inline-block;
      margin-left: 15px;
      img {
        width: 100%;
      }
    }
  }
}
.slide-thumb {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  img {
    margin-right: 20px;
    &.active {
      box-shadow: 0 0 6px rgba(0, 0, 0, 1);
    }
  }
}

// Shop Position Style
.map-btm {
  position: absolute;
  bottom: 50px;
  padding: 10px 15px;
  width: 100%;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .gps-btn {
    align-self: flex-end;
  }
  .btn-sec {
    display: flex;
    flex-flow: column;
    .btn-black {
      min-width: 150px;
      text-transform: uppercase;
    }
  }
}

// Vote Sec Style
.vote-sec {
  position: relative;
  .msg-bubble {
    width: 204px;
    position: absolute;
    top: 10px;
    left: 0;
    .cont {
      position: absolute;
      top: 28px;
      left: 34px;
      width: 70%;
      text-align: center;
      p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
  .avatar-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $black;
    display: flex;
    align-items: center;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    position: absolute;
    bottom: -10px;
    left: calc(50% - 75px);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid $yellow;
    }
  }
  .winner-sec {
    background: transparent;
    margin-top: -210px;
    margin-bottom: 0;
    min-height: unset;
    .winner-img {
      position: static;
      margin: 0 auto;
    }
    .winner-badge {
      margin-top: -20px;
      img.move-up {
        margin-top: -18px;
      }
      .badge-cont {
        top: 16%;
        p {
          color: $dark;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-shadow: none;
          -moz-text-shadow: none;
          -webkit-text-shadow: none;
          img {
            width: 19px;
          }
        }
        .win {
          position: absolute;
          left: -2px;
          top: 6px;
          color: $white;
          font-size: 27px;
          font-weight: 600;
          text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.6);
          -moz-text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
          -webkit-text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  .vote-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    flex-direction: row;
    text-align: center;
    background-color: #ffffff;
    padding-top: 35px;
    padding-bottom: 60px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -18px;
    margin-bottom: -40px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    &.vote-first-place {
      padding-top: 60px;
      padding-bottom: 35px;
      margin-top: -45px;
      margin-bottom: -15px;
      &.election-vote-history {
        margin-bottom: 30px;
        margin-top: 20px;
        padding: 10px;
      }
      .count-content {
        box-shadow: 0px 0px 20px 0px rgba(253, 213, 25, 1);
      }
      .count-content-99 {
        box-shadow: 0px 0px 20px 0px rgba(8, 7, 8, 1);
      }
    }
    .count-content {
      width: 101px;
      height: 101px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      border: 5px solid #080708;
      border-radius: 50%;
      box-shadow: 0px 0px 20px 0px rgba(8, 7, 8, 1);
      h4 {
        font-size: 20px;
        font-weight: 700;
        margin-top: -18px;
        margin-bottom: 0px;
      }
      p {
        font-size: 10px;
        font-weight: 700;
        padding-bottom: 0px;
        margin-bottom: 0px;
        margin-left: 10px;
        margin-right: 10px;
        border-top: 1px solid #707070;
        padding-top: 3px;
      }
    }
  }
}
.vote-user {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  margin-top: 26px;
  text-align: center;
  background: transparent;
}
.voted-img {
  margin: 10px 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
a.continue {
  color: $dark;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}
.alert-bubble-img {
  position: relative;
  width: 350px;
  margin-top: 30%;

  &.min-mt {
    margin-top: -90px;
  }
  .cont {
    position: absolute;
    top: 28%;
    left: 0px;
    padding: 0 24%;
    width: 100%;
    h5 {
      margin-top: 20px;
      font-weight: bolder;
      text-align: center;
      font-size: 26px;
    }
    p {
      color: $white;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
.button-btm-sec {
  position: fixed;
  left: 0;
  bottom: 30px;
  width: 100%;
  padding: 0 20px;
  .text-link {
    color: $dark;
    text-transform: uppercase;
    margin: 20px auto 0;
    display: table;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }
}

// Login Style Starts
.yellow-bg {
  background: $yellow;
  min-height: calc(100vh - 57px);
  padding-bottom: 0;
  position: relative;
  &.with-gray {
    background: $light-gray;
    .performance-sec {
      // min-height: unset;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      margin-top: -30px;
      .per-cont {
        position: static;
        margin: 0 auto;
        .big-circle {
          margin: 0 auto;
        }

        &.dd {
          position: relative;
        }
      }
      .status {
        position: absolute;
        left: 0;
        bottom: 35px;
        font-size: 12px;
        margin: 0;
        text-align: center;
        width: 100%;
        display: inline-block;
      }
    }
    .val {
      color: #b7b7b7;
      font-size: 12px;
    }
    .rate-tag {
      color: $dark;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      width: 100%;
      margin-top: 10px;
    }
  }
  .login-wrap {
    overflow-x: hidden;
    .sign-logo {
      max-width: 280px;
      display: block;
      margin: 25px auto;
    }
    h5 {
      font-size: 25px;
      font-weight: 500;
      color: $dark;
      text-align: center;
    }
    .login-form {
      width: 290px;
      margin: 0 auto;
      @media only screen and (min-width: 360px) {
        width: 335px;
      }
      .form-group {
        position: relative;
      }
      .ico {
        width: 24px;
        position: absolute;
        left: 15px;
        top: 16px;
      }
      .form-control {
        background: url('../images/input-bg.svg') no-repeat 0 0;
        border: none;
        background-size: 100% 100%;
        height: 54px;
        padding-left: 50px;
      }
      .forgot {
        color: $gray1;
        font-size: 14px;
        text-decoration: none;
      }
      p {
        font-size: 16px;
        color: $gray1;
        text-align: center;
      }
      .btn-yellow {
        width: 65%;
        display: block;
        margin: 0 auto;
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
        img {
          position: absolute;
          left: 26px;
          top: 10px;
        }
        &.fb {
          background: #2d509b;
          color: $white;
          margin-bottom: 15px;
        }
        &.google {
          background: $white;
          border: 1px solid #c9c9c9;
        }
      }
      .btm-line {
        margin: 30px 0;
        a {
          color: $dark;
          text-decoration: none;
        }
      }
    }
    &.election-form {
      h5 {
        margin: 30px 0 20px;
        font-size: 25px;
        &.green {
          color: #06d81e;
        }
      }
      .copy-txt {
        color: #06d81e;
        font-size: 12px;
        font-weight: 700;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
      .seperator {
        display: inline-block;
        width: 100%;
        height: 15px;
        background: $yellow-dark;
      }
      .product-wrap {
        .title-wrap {
          background: url('../images/yellow-title-bg-dark.svg') no-repeat 0 bottom;
        }
      }
      .register-form {
        display: inline-block;
        h6 {
          display: inline-block;
        }
        .btn-empty {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 15px 12px 20px;
          font-size: 16px;
          .btn-right {
            color: $border-gray;
            img {
              width: 8px;
              margin-left: 5px;
              opacity: 0.5;
            }
          }
        }
        .btn-transparent.info {
          position: absolute;
          padding: 0;
          right: 0;
          bottom: 0;
        }
        .form-control {
          width: 100%;
          padding: 10px 12px 10px 25px;
          border: none;
          font-size: 15px;
        }
        .form-check {
          font-size: 15px;
          line-height: 22px;
          .form-check-input {
            border-color: $white;
            border-width: 3.5px;
            &:checked {
              border-color: $white;
              background: $dark;
            }
          }
        }
        .check-info {
          clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
          -moz-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
          -webkit-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
          background: #febf1f;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 25px;
          margin-top: 15px;
          .ico {
            width: 32px;
          }
          p {
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

// Register Style Starts
.select-register {
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  .blk {
    background: url('../images/register-shape-bg.png') no-repeat 0 0;
    background-size: cover;
    display: flex;
    min-height: 140px;
    align-items: center;
    padding: 10px 40px;
    @media only screen and (min-width: 390px) {
      width: 375px;
      margin: 0 auto;
    }
    &.business {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      .ico,
      .cont {
        transform: rotateY(-180deg);
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
      }
      .cont {
        padding-right: 0;
        padding-left: 10px;
      }
    }
    .ico {
      width: 60px;
      margin-top: 5px;
    }
    .cont {
      width: calc(100% - 60px);
      padding-left: 25px;
      padding-top: 20px;
      h5 {
        color: $yellow;
        font-size: 20px;
        text-align: left;
      }
      p {
        font-size: 14px;
        color: $white;
        font-weight: 300;
      }
    }
  }
}

.user-select {
  padding-bottom: 60px;
  .btm-line {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    color: $text-gray;
    font-size: 16px;
    a {
      color: $dark;
      text-decoration: none;
    }
  }
}

// Register Form Style
.register-form {
  .avatar-img {
    width: 103px;
    height: 103px;
    margin: 40px auto;
    border: 2px solid $dark;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    .input-file {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 9;
      height: 100%;
      input[type='file'] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 99;
      }
      .btn {
        position: absolute;
        left: 30px;
        padding: 0;
        top: 34px;
        z-index: -9;
      }
    }
  }
  .form-group {
    &.bg {
      background: $white;
      clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
      min-height: 100px;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      flex-flow: column;
      &.business-radio-encl {
        background: $dark;
        min-height: unset;
        h6 {
          color: $white;
          margin-bottom: 13px;
          span {
            color: $yellow;
          }
        }
        .form-check {
          display: inline-block;
          .form-check-input {
            width: 20px;
            height: 20px;
            border: 2px solid $text-gray;
            background-color: transparent;
            margin-top: 3px;
            margin-right: 8px;
          }
          label {
            color: $text-gray;
            font-size: 18px;
            text-transform: uppercase;
          }
        }
      }
    }
    .form-control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dddddd;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .req-row {
    font-size: 18px;
    color: $dark;
    margin-top: 20px;
    small {
      font-size: 14px;
      display: inline-block;
      width: 100%;
      margin-top: 5px;
    }
  }
  h6 {
    font-weight: 400;
    font-size: 18px;
    color: $dark;
    strong {
      font-weight: 500;
    }
  }
  .select-pop {
    opacity: 0.65;
    display: flex;
    justify-content: space-between;
    .ico {
      width: 16px;
      transform: rotate(270deg);
      position: relative;
      top: -5px;
    }
  }
  .shop-pic {
    position: relative;
    width: 90px;
    height: auto;
    input[type='file'] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .map-blk {
    width: 100%;
    height: 200px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24);
    position: relative;
    margin-bottom: 20px;
    iframe {
      width: 100%;
      height: 100%;
    }
    .btn-white {
      text-transform: uppercase;
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 14px;
    }
    & + .form-check {
      position: relative;
      padding-left: 38px;
      .form-check-input {
        width: 20px;
        height: 20px;
        border-radius: 0 !important;
        border: 2px solid $dark;
        background: $white;
        margin-left: -38px;
        &:checked[type='checkbox'] {
          & + .check-mark {
            display: block;
          }
        }
      }
      .check-mark {
        display: none;
        position: absolute;
        left: -3px;
        top: -2px;
      }
    }
  }
  .thick-line {
    width: 80%;
    height: 2px;
    background: $dark;
    opacity: 1;
    margin: 0 auto;
  }
  .edit-btn {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}

.reg-modal {
  .alert-bubble-img {
    h5 {
      font-size: 16px;
      color: $white;
      margin-top: 10px;
      &.dark {
        color: $dark;
      }
    }
    p {
      font-size: 12px;
      color: $dark;
      font-weight: 400;
    }
  }
}

#category-modal {
  .modal-content {
    margin-left: 35px;
    margin-right: 35px;
  }
}

.category-list {
  max-height: 89vh;
  overflow-y: auto;
  background: $white;
  padding: 25px;
  h6 {
    font-size: 15px;
    font-weight: 400;
    color: $dark;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 8px 0;
  }
  ul {
    margin: 0;
    li {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 5px 8px 25px;
      border-top: 1px solid $border-gray;
      &:last-child {
        border-bottom: 1px solid $border-gray;
      }
      .ico {
        width: 16px;
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
}

// Working Hours Style
.work-hrs {
  display: block;
  width: 95%;
  margin: 50px auto 0;
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }
  .title {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: $dark;
    font-weight: 600;
    border-bottom: 1px solid $text-gray;
    padding-bottom: 8px;
  }
  .day {
    background: $white;
    clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
    -moz-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(4% 6%, 100% 0, 100% 100%, 0% 100%);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 27px;
    font-size: 12px;
    font-weight: 600;
  }
  .day-input {
    padding: 0 5px;
    border-radius: 0;
    border: none;
    font-size: 11px;
    height: 27px;
    background-color: $white;
    text-align: center;
  }
}

// Business Preview Style Starts
.full-view-img {
  .product-list {
    overflow: visible;
    .product-overflow {
      .prod-snip {
        .full-view {
          width: 100vw;
          margin-left: -0.77rem;
          &:after {
            display: none;
          }
        }
        .tri-clip {
          &:before {
            content: '';
            background: $white;
            clip-path: polygon(0 14%, 100% 45%, 100% 100%, 0% 100%);
            -moz-clip-path: polygon(0 14%, 100% 45%, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(0 14%, 100% 45%, 100% 100%, 0% 100%);
            // clip-path: polygon(0 10%, 100% 33%, 100% 100%, 0% 100%);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            z-index: -9;
          }
        }
        &.h-reduced {
          .full-view {
            height: 260px;
          }
          .tri-clip {
            &:before {
              clip-path: polygon(0 23%, 100% 65%, 100% 100%, 0% 100%);
            }
          }
        }
      }
    }
  }
}

// General Filter Style
.general-filter {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: auto;
  right: 0;
  background: rgba(222, 223, 222, 0.7);
  z-index: 99;
  margin-top: -2px;
  backdrop-filter: blur(5px);
  .btn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: $text-gray;
    img {
      margin-left: 10px;
    }
  }
}

// Search Listing Style
.top-search-category {
  position: fixed;
  left: 0;
  top: auto;
  width: calc(100% + 3rem);
  background: $white;
  padding: 10px 20px;
  .search-row {
    display: flex;
    width: 100%;
    padding: 0;
    .form-check {
      width: 33.33%;
      padding-left: 28px;
      label {
        color: $text-gray;
        font-size: 13px;
        text-transform: uppercase;
      }
      .form-check-input {
        width: 20px;
        height: 20px;
        margin-top: 0;
        margin-left: -26px;
        &:checked {
          background-color: $dark;
          border-color: $dark;
        }
      }
    }
  }
}
.search-listing {
  display: inline-block;
  width: 100%;
  padding-top: 0px;
  backdrop-filter: blur(5px);
  h6 {
    font-size: 16px;
    color: $dark;
    font-weight: 400;
    text-transform: uppercase;
  }
  .search-item {
    li {
      // border-bottom: 1px solid #dedede;
      a {
        width: 100%;
        display: inline-block;
        color: $dark;
        font-size: 13px;
        text-decoration: none;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

// Election Section Style
.elect-sec {
  background: -moz-linear-gradient(
    top,
    rgba(253, 213, 25, 1) 0%,
    rgba(243, 239, 218, 0) 88%,
    rgba(242, 242, 242, 0) 99%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(253, 213, 25, 1) 0%,
    rgba(243, 239, 218, 0) 88%,
    rgba(242, 242, 242, 0) 99%
  );
  background: linear-gradient(
    to bottom,
    rgba(253, 213, 25, 1) 0%,
    rgba(243, 239, 218, 0) 88%,
    rgba(242, 242, 242, 0) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdd519', endColorstr='#00f2f2f2',GradientType=0 );
  z-index: 99;
  padding-bottom: 10px;
  .avatar-img {
    bottom: auto;
    top: 60px;
    z-index: 99;
  }
}

.elect-cont {
  margin-top: -31%;
  position: relative;
  text-align: center;
  .cont {
    position: absolute;
    left: 0;
    width: 100%;
    top: 68px;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;

    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      justify-content: center;
      margin-top: 0px;

      img {
        width: 17px;
        margin-right: 5px;
      }
    }
  }
}

// Scan Sec Style
.scan-sec {
  width: 100%;
  position: relative;
  height: calc(100vh - 60px);
  background: rgb(183, 183, 183);
  background: -moz-linear-gradient(-45deg, rgba(183, 183, 183, 1) 0%, rgba(5, 8, 19, 1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(183, 183, 183, 1) 0%, rgba(5, 8, 19, 1) 100%);
  background: linear-gradient(135deg, rgba(183, 183, 183, 1) 0%, rgba(5, 8, 19, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7b7b7', endColorstr='#050813',GradientType=1 );
  border: 5px solid $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .placeholder-text {
    font-size: 22px;
    color: $dark;
    font-weight: 500;
    display: inline-block;
    text-align: center;
  }
  .info-text {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 0 20px 20px;
    text-align: center;
    font-size: 14px;
    text-shadow: 0 0 5px #fff;
    -moz-text-shadow: 0 0 5px #fff;
    -webkit-text-shadow: 0 0 5px #fff;
  }
}

// Chat Style
.chat-snippet {
  padding-right: 10px;
  margin-bottom: 8px;
  .text-success {
    color: #06d81e !important;
  }
  .elec {
    font-size: 12px;
    font-weight: 300;
    color: $dark;
    display: flex;
    align-items: center;
    margin: 3px 0 5px;
    img {
      width: 17px;
      margin-right: 5px;
    }
    strong {
      font-weight: 700;
      margin-left: 2px;
    }
  }
  .msg {
    font-size: 12px;
    color: $dark;
    margin-bottom: 0;
  }
  .unread {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding-right: 5px;
    width: 55px;
    .ico {
      width: 30px;
      position: relative;
      margin-top: 10px;
      .count {
        position: absolute;
        background: rgb(6, 216, 30);
        background: -moz-linear-gradient(-45deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
        background: -webkit-linear-gradient(-45deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
        background: linear-gradient(135deg, rgba(6, 216, 30, 1) 0%, rgba(39, 180, 61, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06d81e', endColorstr='#27b43d',GradientType=1 );
        color: $white;
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
        bottom: -4px;
        right: -3px;
      }
    }
    .time {
      font-size: 10px;
      color: $dark;
      margin-top: 8px;
    }
  }
  .user-img {
    &.logo {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      img {
        border-color: $white;
      }
    }
  }
}

.chat-head {
  display: flex;
  align-items: center;
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid $dark;
    img {
      width: 100%;
      height: 100%;
      border: 3px solid $yellow;
      border-radius: 50%;
    }
  }
  &.vf-assist {
    .avatar {
      border-color: $white;
      img {
        border-color: $white;
      }
    }
  }
  .user-cont {
    width: calc(100% - 50px);
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    text-transform: none;
    .name {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      margin-bottom: 2px;
    }
    small {
      font-size: 12px;
      color: $text-gray;
      opacity: 0.5;
      font-weight: 400;
      display: inline-block;
      width: 100%;
    }
  }
}

.chat-sec {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url('../images/chat-bg.png') no-repeat 0 0;
  background-size: cover;
  left: 0;
  top: 0;
  padding-top: 65px;
  overflow: hidden;
  .chat-area {
    display: inline-block;
    height: calc(100% - 65px);
    width: 100%;
    overflow-y: auto;
    padding: 0 10px;
    .msg-row-parent {
      filter: drop-shadow(-1px 4px 3px rgba(0, 0, 0, 0.18));
      -moz-filter: drop-shadow(-1px 4px 3px rgba(0, 0, 0, 0.18));
      -webkit-filter: drop-shadow(-1px 4px 3px rgba(0, 0, 0, 0.18));
      display: inline-block;
      width: 100%;
      margin: 5px 0;
      &.sent {
        text-align: right;
        .msg-row {
          clip-path: polygon(0 0, 92% 7%, 94% 85%, 100% 100%, 0 100%);
          -moz-clip-path: polygon(0 0, 92% 7%, 94% 85%, 100% 100%, 0 100%);
          -webkit-clip-path: polygon(0 0, 92% 7%, 94% 85%, 100% 100%, 0 100%);
          background: $white;
          padding: 15px 8% 10px 10px;
          p {
            text-align: left;
          }
          .time {
            right: 20px;
            display: flex;
            align-items: center;
            .ico {
              margin-left: 3px;
            }
          }
        }
      }
      .msg-row {
        clip-path: polygon(7% 5%, 100% 0, 100% 100%, 0 100%, 5% 75%);
        -moz-clip-path: polygon(7% 5%, 100% 0, 100% 100%, 0 100%, 5% 75%);
        -webkit-clip-path: polygon(7% 5%, 100% 0, 100% 100%, 0 100%, 5% 82%);
        background: $yellow-chat;
        padding: 15px 10px 10px 8%;
        max-width: 80%;
        min-width: 35%;
        position: relative;
        display: inline-block;
        &.dark-bg {
          background: #212121;
          p {
            color: $white;
          }
        }
        p {
          font-size: 13px;
          color: $dark;
          margin-bottom: 10px;
        }
        .time {
          font-size: 10px;
          color: #b7b7b7;
          position: absolute;
          right: 10px;
          bottom: 5px;
        }
      }
    }
  }
  .type-area {
    position: absolute;
    bottom: 8px;
    left: 8px;
    padding: 0;
    width: calc(100% - 16px);
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 3% 8%);
    background: $gray1;
    height: 54px;
    .type-inside {
      clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 3% 8%);
      background: $white;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
      display: flex;
      align-items: center;
      .btn-emoti {
        margin-left: 5px;
      }
      .form-control {
        border: none;
        width: calc(100% - 58px);
        font-size: 14px;
      }
    }
  }
}

.head-dd {
  .dropdown-toggle {
    img {
      width: 3px;
      max-width: unset;
    }
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    border: none;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);
    -moz-backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    top: 15px !important;
    min-width: 13rem;
    li {
      padding-left: 15px;
      padding-right: 15px;
    }
    .dropdown-item {
      font-size: 12px;
      color: $dark;
      border-bottom: 1px solid $text-gray;
      padding: 10px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        width: 13px;
        transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
      }
    }
  }
}

// User Page Style
.um-sec {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  margin-top: -62px;
  .link {
    width: 33.33%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    .ico-blk {
      display: inline-flex;
      width: auto;
      position: relative;
      min-height: 85px;
      align-items: flex-end;
      .count {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $yellow;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
        font-size: 12px;
        font-weight: 500;
        border-radius: 50%;
      }
      .place {
        position: absolute;
        font-size: 27px;
        font-weight: 700;
        color: $white;
        text-shadow: 0 3px 5px $black;
        bottom: -24px;
        left: 11px;
      }
    }
    p {
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 11px;
      color: $dark;
      line-height: 14px;
    }
  }
}

// QR Style
.qr-wrap {
  .avatar-img {
    width: 150px;
    height: 150px;
    border: 2px solid $dark;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 4px solid $yellow;
      border-radius: 50%;
    }
    &.business {
      width: 110px;
      height: 110px;
      img {
        border-color: $white;
      }
    }
  }
  h5 {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    color: $dark;
  }
  .qr-img {
    display: flex !important;
    align-items: center;
    justify-content: center;
    display: inline-block;
    margin-top: 45px;
  }
}

// Winner Ribbon Style
.win-rib-sec {
  .candidate-snippet {
    position: relative;
    padding-left: 15px !important;
    .winner-ribbon {
      position: absolute;
      left: 0px;
      top: 30px;
      text-align: center;
      display: flex;
      flex-flow: column;
      img {
        max-width: 100%;
      }
      span {
        font-size: 22px;
        color: $white;
        text-shadow: 0 0 5px $black;
        -moz-text-shadow: 0 0 5px $black;
        -webkit-text-shadow: 0 0 5px $black;
      }
    }
    .btn-clip {
      background: $yellow;
      clip-path: polygon(55% 0, 100% 0, 100% 100%, 0 100%, 4% 18%);
      -moz-clip-path: polygon(55% 0, 100% 0, 100% 100%, 0 100%, 4% 18%);
      -webkit-clip-path: polygon(55% 0, 100% 0, 100% 100%, 0 100%, 4% 18%);
      border-radius: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 17px;
      font-size: 15px;
      font-weight: 500;
      img {
        width: 10px;
      }
    }
  }
}

// At the Shop Style
.at-shop {
  width: 320px;
  display: inline-block;
  margin: 20px auto 40px;
  .top-cont {
    position: relative;
    width: 100%;
    display: inline-block;
    .cont {
      position: absolute;
      left: 0;
      top: 39%;
      width: 100%;
      padding: 0 10%;
      display: flex;
      align-items: center;
      .ico {
        width: 28px;
        margin-right: 15px;
      }
      p {
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }
  .btn-sec {
    display: inline-block;
    width: 100%;
    .btn-row {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 15px;
      .ico-blk {
        position: relative;
        width: 62px;
        z-index: 99;
        &:before {
          content: '';
          position: absolute;
          left: 30px;
          top: -22px;
          bottom: 0;
          height: 200%;
          width: 3px;
          background: $dark;
          z-index: -9;
        }
      }
      &:last-child {
        .ico-blk:before {
          display: none;
        }
      }
      .btn-wrap {
        width: calc(100% - 62px);
        padding-left: 3px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -17px;
          top: 23px;
          background: $white;
          width: 60px;
          height: 22px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.13);
        }
        .btn-clip-parent {
          filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.18));
          -moz-filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.18));
          -webkit-filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.18));
        }
        .btn-clip {
          width: 100%;
          background: $white;
          clip-path: polygon(0 0, 95% 10%, 100% 100%, 0% 100%);
          -moz-clip-path: polygon(0 0, 95% 10%, 100% 100%, 0% 100%);
          -webkit-clip-path: polygon(0 0, 95% 10%, 100% 100%, 0% 100%);
          padding: 15px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 75px;
          span {
            font-size: 12px;
            color: $dark;
          }
          a {
            text-decoration: none;
            color: $dark;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            margin-right: 10px;
            img {
              width: 8px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 375px) {
    width: 375px;
    .btn-sec {
      .btn-row {
        padding: 0 6px;
        .btn-wrap {
          .btn-clip {
            a {
              font-size: 12px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.product-modal {
  .modal-content {
    padding: 30px;
  }
  .product-wrap .title-wrap h3 {
    font-size: 16px;
  }
}

// Gift Free Info Style
.free-gift-info {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  p {
    font-size: 13px;
    &.lead {
      font-size: 18px;
      margin-top: 35px;
      font-weight: 400;
    }
  }
  .divider {
    height: 4px;
    opacity: 1;
    background: $dark;
    width: 260px;
    margin: 60px auto;
  }
}

// Gift Ribbon Popup Style
#gift-received-modal {
  .modal-content {
    padding: 0;
  }
}
.gift-rib {
  display: inline-block;
  width: 100%;
  min-height: 200px;
  padding: 0 20px;
  &.min-mt {
    margin-top: -80px;
    @media only screen and (max-width: 370px) {
      margin-top: -50px;
    }
  }
  .gift-ribbon-wrap {
    width: 360px;
    margin: 0 auto;
    position: relative;
    display: block;
    .gift-img {
      position: absolute;
      bottom: 38px;
      left: 46px;
      width: 259px;
      background: $white;
      height: 207px;
      z-index: -9;
      .img-wrap {
        width: 100%;
        height: 147px;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img-count {
          position: absolute;
          left: 10px;
          bottom: 10px;
          z-index: 9;
          display: flex;
          align-items: center;
          color: #c5c5c5;
          font-size: 12px;
          padding: 0;
          .ico {
            width: 16px;
            margin-right: 3px;
          }
        }
      }
      span {
        display: inline-block;
        width: 100%;
        padding: 0 15px;
      }
      .title {
        font-size: 14px;
        color: $dark;
        font-weight: 400;
        margin-top: 3px;
        margin-bottom: 0;
      }
      .gift-title {
        font-size: 20px;
        font-weight: 600;
        color: $dark;
        text-shadow: 0 0 12px #fdd519;
        -moz-text-shadow: 0 0 12px #fdd519;
        -webkit-text-shadow: 0 0 12px #fdd519;
      }
    }
  }
  p.lead {
    font-size: 18px;
    color: $dark;
    text-align: center;
    margin: 30px 0;
    font-weight: 400;
  }
  h4 {
    font-size: 18px;
    color: $dark;
    font-weight: 700;
    text-align: center;
    margin: 10px 0 40px;
    display: inline-block;
    width: 100%;
  }
  @media only screen and (max-width: 399px) {
    .gift-ribbon-wrap {
      width: 280px;
      .gift-img {
        bottom: 30px;
        left: 36px;
        width: 202px;
        height: 160px;
        .img-wrap {
          height: 108px;
        }
      }
    }
  }
  .divider {
    height: 4px;
    opacity: 1;
    background: #080708;
    width: 260px;
    margin: 60px auto;
  }
}

.star-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .star-encl {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      margin-right: 3px;
      &.gray {
        filter: brightness(0);
        -moz-filter: brightness(0);
        -webkit-filter: brightness(0);
        opacity: 0.5;
      }
    }
  }
}

// Confirm Address Style
.confirm-address {
  clip-path: polygon(3% 2%, 100% 0, 100% 100%, 0% 100%);
  -moz-clip-path: polygon(3% 2%, 100% 0, 100% 100%, 0% 100%);
  -webkit--clip-path: polygon(3% 2%, 100% 0, 100% 100%, 0% 100%);
  background: $white;
  padding: 30px;
  .form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
  }
  .form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $border-gray;
    font-size: 16px;
    color: $dark;
    padding-left: 0;
    padding-right: 0;
  }
  .data-info {
    display: inline-block;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 45px;
    h4 {
      text-align: left;
      margin: 0 0 12px 0;
    }
  }
  .btn-modify {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin: 10px auto 25px;
  }
  .form-check {
    padding-left: 30px;
    display: table;
    margin: 0 auto;
    width: auto;
    .form-check-input {
      position: relative;
      width: 20px;
      height: 20px;
      border: 2px solid $gray1;
      border-radius: 0;
      margin-left: -30px;
      margin-top: 0;
      &:after {
        content: '';
        background: url(../images/checkbox-check.svg) no-repeat 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 24px;
        height: 28px;
        background-size: 100%;
        display: none;
      }
      &:checked {
        background: transparent;
        &:after {
          display: block;
        }
      }
    }
    .form-check-label {
      color: $gray1;
      font-size: 16px;
    }
  }
}

.modal-content {
  &.fix-btm-space {
    padding-bottom: 120px;
  }
  .btm-sec {
    .alert-sec {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 25px;
      .alert-ico {
        width: 75px;
        margin-right: 10px;
      }
      p {
        width: calc(100% - 85px);
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}

#shipped-status-modal {
  .gift-rib {
    margin-top: -20px;
    h4 {
      font-size: 24px;
    }
  }
}

// Feedback Pop Style
.feedback-pop {
  display: inline-block;
  width: 100%;
  position: relative;
  .fdb-bubble {
    position: relative;
    width: 261px;
    left: -37px;
    margin-top: -15px;
    margin-bottom: -12px;
    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      top: 25px;
      left: 58px;
      width: 195px;
      text-align: center;
      padding: 0 5px;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
  }
  .star-row {
    display: flex;
    align-items: center;
    justify-content: center;
    .ico {
      width: 40px;
      margin: 0 2px;
    }
  }
  h6 {
    font-size: 14px;
    color: $text-gray;
    font-weight: 400;
    text-align: center;
    margin: 15px 0;
  }
  .comment {
    background: $text-gray;
    clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
    -moz-clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
    -webkit-clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
    position: relative;
    height: 170px;
    textarea {
      clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
      -moz-clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
      -webkit-clip-path: polygon(3% 4%, 60% 0, 100% 0, 100% 100%, 0 100%, 0 67%);
      background: $white;
      border: none;
      resize: none;
      position: absolute;
      width: calc(100% - 2px);
      left: 1px;
      top: 1px;
      height: calc(100% - 2px);
      padding: 15px 20px;
      font-size: 14px;
      color: $dark;
      &:focus {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        outline: none;
      }
    }
  }
}

// Ask for Vote Style
.ask-vote-wrap {
  background: $yellow;
  padding: 30px 0;

  .vote-ico {
    width: 57px;
    display: block;
    margin: 0 auto;
  }
  h4 {
    font-size: 18px;
    padding: 0 30px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 5px;
  }
  h6 {
    @extend h4;
    font-size: 12px;
    margin-top: 20px;
  }
  .choose-prod-thumb {
    // display: flex;
    width: 100%;
    padding: 0 15px;
    // justify-content: none;
    margin-bottom: 20px;
    overflow: scroll;
    white-space: nowrap;
    .thumb {
      width: 85px;
      height: 85px;
      margin: 10px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // overflow: hidden;
      position: relative;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      .pd-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .ico {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        position: absolute;
        left: auto;
        top: auto;
        background: $dark;
      }
    }
  }
}

.layout-wrap-encl {
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.layout-wrap {
  display: flex;
  width: auto;
  padding: 20px 0 20px 60px;
}
.layout-thumb {
  width: 250px;
  display: inline-block;
  margin-right: 25px;
  box-shadow: 0 0 12px $gray1;
  -moz-box-shadow: 0 0 12px $gray1;
  -webkit-box-shadow: 0 0 12px $gray1;
  position: relative;
  overflow: hidden;
  .cont {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    padding: 10px;
    .avatar {
      width: 43px;
      height: 43px;
      border: 2px solid $dark;
      border-radius: 50%;
      display: flex;
      align-items: center;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid $yellow;
      }
    }
    .avatar-cont {
      display: flex;
      align-items: center;
      -webkit-transform: rotate(331deg);
      -moz-transform: rotate(331deg);
      transform: rotate(331deg);
      position: relative;
      top: -16px;
      left: -13px;
      width: 153px;
      justify-content: space-between;
      .name {
        font-size: 12px;
        color: $dark;
        margin-right: 10px;
        display: inline-block;
        width: 100px;
        text-align: center;
      }
      .logo {
        width: 40px;
      }
    }
    .prod-thumb {
      width: 130px;
      display: block;
      margin: -8px auto 13px;
      height: 130px;
      background: $white;
      padding: 5px;
      .thumb-in {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 5px solid $dark;
        .status {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          display: flex;
          align-items: flex-start;
          span {
            position: absolute;
            left: 0;
            top: -1px;
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
        .prod-img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        .business-logo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          left: 3px;
          bottom: 3px;
          display: flex;
          align-items: center;
          border: 2px solid $dark;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid $white;
          }
        }
      }
    }
  }
  .btn {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    &.btn-with-avatar {
      display: flex;
      justify-content: center;
      position: relative;
      .avatar {
        position: absolute;
        left: 1px;
        top: 1px;
        width: 28px;
        height: 28px;
        &.edge {
          width: 32px;
          height: 32px;
          left: -1px;
          top: -1px;
        }
      }
    }
  }
  &.lay-2 {
    .prod-thumb {
      width: 94px;
      display: block;
      height: 94px;
      border: none;
      border-radius: 50%;
      padding: 0;
      overflow: hidden;
      margin-top: 28px;
      .thumb-in {
        border: none;
      }
    }
    .av-1,
    .av-2,
    .av-3,
    .av-4,
    .av-5 {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .av-2 {
      right: 128px;
      width: 25px;
      height: 25px;
      top: 7px;
    }
    .av-3 {
      width: 28px;
      height: 28px;
      top: 60px;
      right: 20px;
    }
    .av-4 {
      width: 20px;
      height: 20px;
      top: 103px;
      right: 37px;
    }
    .av-5 {
      width: 22px;
      height: 22px;
      top: 70px;
      right: auto;
      left: 9px;
    }
    .business-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: 18px;
      top: 12px;
      display: flex;
      align-items: center;
      border: 2px solid $dark;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid $white;
      }
      &.bl-1 {
        width: 28px;
        height: 28px;
        top: 103px;
        left: 42px;
      }
      &.bl-2 {
        width: 22px;
        height: 22px;
        left: auto;
        right: 60px;
        top: 29px;
      }
    }
    .vf-logo {
      display: inline-block;
      padding: 23px 0 12px;
      text-align: center;
      width: 100%;
    }
  }
  &.lay-3 {
    .cont {
      .avatar {
        position: absolute;
        right: 18px;
        top: 10px;
        width: 70px;
        height: 70px;
      }
      .avatar-cont {
        transform: rotate(0);
        -moz-transform: rotate(0);
        -webkit-transform: rotate(0);
        width: auto;
        top: 0;
        left: 0;
        .logo {
          width: 45px;
        }
        .name {
          width: auto;
          margin: 0;
          position: absolute;
          left: -7px;
          top: 24px;
          transform: rotate(-29deg);
          -moz-transform: rotate(-29deg);
          -webkit-transform: rotate(-29deg);
          font-size: 10px;
          font-weight: 600;
        }
      }
      .prod-thumb {
        padding: 0;
        width: 100px;
        height: 100px;
        margin-top: 16px;
        position: relative;
        z-index: -9;
        margin-bottom: 61px;
        .thumb-in {
          border: none;
          .business-logo {
            width: 35px;
            height: 35px;
            bottom: -8px;
            left: -4px;
          }
        }
      }
    }
  }
  &.lay-4 {
    .lamp-img {
      position: absolute;
      width: 195px;
      left: 20px;
      top: -2px;
      z-index: 99;
    }
    .cont {
      .prod-thumb {
        margin-top: 59px;
        padding: 0;
        position: relative;
        width: 116px;
        left: -6px;
        height: 112px;
        margin-bottom: 29px;
        .thumb-in {
          border: none;
        }
      }
    }
  }
  &.lay-5 {
    .cont {
      padding: 0;
      .prod-thumb {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        .thumb-in {
          border: none;
          .status {
            span {
              top: 1px;
            }
          }
          .business-logo {
            width: 40px;
            height: 40px;
            bottom: auto;
            top: 22px;
            left: 5px;
          }
        }
      }
    }
    .btn-yellow {
      z-index: 99;
      position: absolute;
      bottom: 8px;
      left: 10px;
      width: calc(100% - 20px);
    }
    &.lay-7 {
      .cont {
        .prod-thumb {
          .thumb-in {
            .business-logo {
              width: 45px;
              height: 45px;
              left: calc(50% - 22.5px);
              top: 12px;
              z-index: 99;
            }
            .pls-vote-badge {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              position: absolute;
              width: 127px;
              height: 170px;
              background: rgba(255, 255, 255, 0.2);
              backdrop-filter: blur(2px);
              -moz-backdrop-filter: blur(2px);
              -webkit-backdrop-filter: blur(2px);
              img {
                width: 77%;
              }
            }
          }
        }
      }
      &.elect-lay {
        border-width: 4px;
        .btn-black {
          width: auto;
          min-width: 140px;
          left: calc(50% - 70px);
          bottom: 5px;
          font-size: 13px;
        }
      }
    }
  }
  &.lay-6 {
    .cont {
      .prod-thumb {
        width: 120px;
        height: 120px;
        padding: 0;
        margin: 0;
        transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -webkit-transform: rotate(-3deg);
        position: relative;
        top: 15px;
        left: 16px;
        .thumb-in {
          border: none;
          .business-logo {
            width: 78px;
            height: 78px;
            right: -68px;
            left: auto;
            bottom: -20px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
            -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
            transform: rotate(3deg);
            -moz-transform: rotate(3deg);
            -webkit-transform: rotate(3deg);
          }
        }
      }
      .vote-txt {
        color: $white;
        font-size: 13px;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 34px;
        transform: rotate(-4deg);
        -moz-transform: rotate(-4deg);
        -webkit-transform: rotate(-4deg);
        text-shadow: 0 0 6px $white;
      }
    }
    .btn-yellow {
      margin-top: 28px;
    }
  }
  &.lay-8 {
    .cont {
      .prod-thumb {
        width: 35px;
        height: 35px;
        padding: 0;
        margin-top: 50px;
        position: relative;
        .thumb-in {
          border-width: 2px;
          position: relative;
          .business-logo {
            width: 35px;
            height: 35px;
            left: -40px;
            top: -35px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
          }
        }
        .avatar {
          position: absolute;
          width: 35px;
          height: 35px;
          right: -40px;
          top: -33px;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        }
      }
      .btn {
        margin-top: 103px;
      }
    }
  }
  &.lay-9 {
    .cont {
      padding: 0;
      .prod-thumb {
        width: 122%;
        height: auto;
        padding: 0;
        background: transparent;
        margin: 0;
        transform: rotate(-20deg);
        -moz-transform: rotate(-20deg);
        -webkit-transform: rotate(-20deg);
        position: relative;
        top: 53px;
        left: -30px;
        .bs-logo-wrap {
          display: inline-block;
          width: 100%;
          .business-logo {
            width: 30px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid $dark;
            margin: 0 2px;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              overflow: hidden;
              border: 2px solid $white;
            }
          }
        }
        .thumb-wrap {
          @extend .bs-logo-wrap;
          margin: 5px 0;
          .thumb-in {
            width: 30px;
            height: 30px;
            border-width: 2px;
            display: inline-flex;
            margin: 0 2px;
          }
        }
        .avatar-encl {
          @extend .bs-logo-wrap;
          .avatar {
            width: 30px;
            height: 30px;
            border-width: 2px;
            display: inline-flex;
            margin: 0 2px;
          }
        }
        .vote-txt {
          color: $white;
          float: right;
          margin-right: 76px;
          text-align: center;
          font-size: 13px;
          margin-top: 16px;
        }
      }
      .btn-black {
        z-index: 99;
        position: absolute;
        bottom: 8px;
        left: 10px;
        width: calc(100% - 20px);
      }
    }
  }
  &.lay-10 {
    .vote-txt {
      display: table;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
      margin-top: 27px;
    }
    .avatar {
      margin: 0;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 10px;
      left: 10px;
      img {
        border-width: 3px;
      }
    }
    .btn {
      margin-top: 78px;
    }
  }
}

.layout-info {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  padding-left: 20px;
  padding-right: 20px;
  .info-row {
    display: flex;
    width: 100%;
    align-items: center;
    em {
      width: 40px;
      height: 40px;
      background: $yellow;
      border: 2px solid $dark;
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    span {
      font-size: 16px;
      color: $dark;
      font-weight: 700;
      width: calc(100% - 40px);
      padding-left: 10px;
    }
  }
}

.modal-lay-wrap {
  background: $light-gray !important;
  .layout-thumb {
    margin: 30px 0;
    width: 100%;
    border: 2px solid $dark;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    .cont {
      .prod-thumb {
        width: 50%;
        height: 50%;
        margin-top: -3%;
        .thumb-in {
          .business-logo {
            width: 40px;
            height: 40px;
          }
          .status {
            span {
              font-size: 13px;
              top: 2px;
            }
          }
        }
      }
      .avatar {
        width: 18%;
        height: 18%;
      }
      .name {
        font-size: 18px;
        font-weight: 600;
        width: calc(100% - 82px);
      }
      .avatar-cont {
        width: 63%;
        top: -24px;
        .logo {
          width: 67px;
        }
      }
      .btn {
        font-size: 0.9rem;
        position: absolute;
        bottom: 15px;
        left: 10px;
        width: calc(100% - 20px);
        &.btn-with-avatar {
          .avatar {
            width: 32px;
            height: 32px;
            &.edge {
              width: 37px;
              height: 37px;
            }
          }
        }
      }
    }
    &.lay-2 {
      .cont {
        .prod-thumb {
          width: 39%;
          height: 39%;
          margin-top: 13.45%;
        }
        .vf-logo {
          padding-top: 14%;
          img {
            width: 27%;
          }
        }
      }
      .av-1 {
        width: 15%;
        height: 15%;
        top: 2%;
        right: 2%;
      }
      .av-2 {
        width: 9%;
        right: 52%;
        height: 9%;
        top: 3%;
      }
      .av-3 {
        width: 12%;
        height: 12%;
        top: 23%;
        right: 10%;
      }
      .av-4 {
        width: 10%;
        height: 10%;
        top: 40%;
        right: 16%;
      }
      .av-5 {
        width: 9%;
        left: 2%;
        height: 9%;
        top: 28%;
      }
      .business-logo {
        width: 15%;
        height: 15%;
        left: 7%;
        top: 4%;
        &.bl-1 {
          width: 11%;
          height: 11%;
          top: 41%;
          left: 17%;
        }
      }
    }
    &.lay-3 {
      .cont {
        .prod-thumb {
          width: 43%;
          height: 43%;
          margin-top: 4%;
          .thumb-in {
            .business-logo {
              width: 27%;
              height: 27%;
              bottom: -8%;
              left: -2%;
            }
          }
        }
        .name {
          font-size: 90%;
          left: -6%;
          top: 107%;
        }
        .avatar {
          width: 26%;
          height: 26%;
          right: 11%;
          top: 7%;
        }
        .avatar-cont {
          width: 50%;
          top: 1%;
          left: 1%;
          .logo {
            width: auto;
            max-width: 100%;
          }
        }
      }
    }
    &.lay-4 {
      .cont {
        .prod-thumb {
          margin-top: 27%;
          margin-bottom: 0;
          width: 49%;
          left: -2.5%;
          height: 46.5%;
          .business-logo {
            width: 45px;
            height: 45px;
            border-width: 3px;
          }
        }
      }
      .lamp-img {
        position: absolute;
        width: 73%;
        left: 10%;
        top: -2%;
        z-index: 99;
      }
    }
    &.lay-5 {
      .cont {
        .prod-thumb {
          width: 100%;
          height: 100%;
          margin-top: 0;
          .thumb-in {
            .status {
              span {
                top: 15%;
              }
            }
          }
          .business-logo {
            width: 70px;
            height: 70px;
            top: 10%;
            img {
              border-width: 3px;
            }
          }
        }
      }
      &.lay-7 {
        .cont {
          .prod-thumb {
            .thumb-in {
              .pls-vote-badge {
                width: 50%;
                height: 74%;
                top: 14%;
                img {
                  width: auto;
                }
                @media only screen and (max-width: 359px) {
                  height: 71%;
                  img {
                    width: 78%;
                  }
                }
              }
              .business-logo {
                width: 23%;
                height: 23%;
                top: 2%;
                left: calc(50% - 11.5%);
              }
            }
          }
        }
      }
    }
    &.lay-6 {
      .cont {
        .prod-thumb {
          width: 54%;
          height: 54%;
          margin-top: 0;
          top: 7%;
          left: 8%;
          .thumb-in {
            .business-logo {
              width: 50%;
              height: 50%;
              right: -40%;
              bottom: -13%;
              img {
                border-width: 3px;
              }
            }
            .status {
              span {
                top: 13%;
              }
            }
          }
        }
        .vote-txt {
          font-size: 94%;
          margin-top: 15%;
        }
      }
    }
    &.lay-8 {
      .cont {
        .prod-thumb {
          width: 20%;
          height: 20%;
          margin-top: 17%;
          .thumb-in {
            .business-logo {
              left: -72%;
              top: -56%;
              width: 40px;
              height: 40px;
            }
          }
          .avatar {
            right: -59%;
            top: -50%;
            width: 40px;
            height: 40px;
          }
          @media only screen and (max-width: 359px) {
            .thumb-in {
              .business-logo {
                left: -95%;
                top: -67%;
              }
            }
            .avatar {
              right: -82%;
              top: -61%;
            }
          }
        }
      }
    }
    &.lay-9 {
      .cont {
        .prod-thumb {
          width: 126%;
          height: auto;
          margin-top: 8%;
          left: -9%;
          .vote-txt {
            margin-top: 6%;
            margin-right: 33%;
            font-size: 100%;
          }
          .thumb-wrap {
            .thumb-in {
              width: 50px;
              height: 50px;
            }
          }
          .bs-logo-wrap {
            .business-logo {
              width: 50px;
              height: 50px;
            }
          }
          .avatar-encl {
            .avatar {
              width: 50px;
              height: 50px;
            }
          }
          @media only screen and (max-width: 399px) {
            margin-top: 3%;
          }
          @media only screen and (max-width: 374px) {
            width: 132%;
            margin-top: 2%;
            transform: rotate(-20deg) scale(0.9);
            -moz-transform: rotate(-20deg) scale(0.9);
            -webkit-transform: rotate(-20deg) scale(0.9);
            left: -13%;
          }
          @media only screen and (max-width: 359px) {
            width: 147%;
            margin-top: -3%;
            transform: rotate(-20deg) scale(0.8);
            -moz-transform: rotate(-20deg) scale(0.8);
            -webkit-transform: rotate(-20deg) scale(0.8);
            left: -21%;
          }
        }
      }
    }
    &.lay-10 {
      .vote-txt {
        font-size: 245%;
        margin-top: 11%;
      }
      .avatar {
        width: 23%;
        height: 23%;
        left: 9%;
        top: 3%;
      }
      @media only screen and (max-width: 359px) {
        .vote-txt {
          font-size: 201%;
          margin-top: 11%;
        }
      }
    }
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  .ss-wrap {
    width: 100%;
    display: inline-block;
    padding: 0;
    background: $white;
    position: relative;
    margin-bottom: 20px;
    &::before {
      content: '';
      background: -moz-linear-gradient(
        left,
        rgba(8, 7, 8, 0) 0%,
        rgba(8, 7, 8, 1) 34%,
        rgba(8, 7, 8, 1) 69%,
        rgba(8, 7, 8, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(8, 7, 8, 0) 0%,
        rgba(8, 7, 8, 1) 34%,
        rgba(8, 7, 8, 1) 69%,
        rgba(8, 7, 8, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(8, 7, 8, 0) 0%,
        rgba(8, 7, 8, 1) 34%,
        rgba(8, 7, 8, 1) 69%,
        rgba(8, 7, 8, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00080708', endColorstr='#00080708',GradientType=1 );
      position: absolute;
      left: 10%;
      top: 0;
      width: 80%;
      height: 1px;
    }
    &::after {
      @extend .ss-wrap::before;
      bottom: 0;
      top: auto;
    }
    .ss-encl {
      width: 100%;
      display: flex;
      overflow-x: auto;
      .ss-row {
        width: auto;
        display: flex;
        padding: 8px 20px;
        margin: 0 auto;
        .ss-blk {
          display: inline-flex;
          align-items: center;
          flex-flow: column;
          margin: 0 10px;
          img {
            width: 50px;
            &.vf-ico {
              width: 109px;
            }
          }
          span {
            display: inline-block;
            margin-top: 6px;
            font-size: 9px;
            font-weight: 600;
            text-transform: uppercase;
            color: $dark;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .btn-close-x {
    position: static;
    padding: 0;
    width: 60px;
    height: 60px;
    align-self: flex-start;
  }
}

// TBD Style
.btn-menu {
  img {
    width: 18px;
    max-width: unset;
  }
}

.performance-secc {
  min-height: 590px;
  background-size: cover;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  position: relative;
  display: flex;
  .see-link {
    // position: absolute;
    // bottom: 10px;
    // left: 0;
    align-self: flex-end;
    justify-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    z-index: 222;
    cursor: pointer;
    a {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: $dark;
      text-decoration: none;
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
      -moz-text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
      -webkit-text-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
    }
  }
  .bg-perf {
    width: 100%;
  }
  .per-cont {
    // position: absolute;
    // left: calc(50% - 160px);
    // top: 30px;

    width: 100%;
    height: 100%;
    margin: 30px auto 15px auto;

    &.see-link {
      width: 100% !important;
      height: 100% !important;
    }
    .btn-empty {
      padding: 0;
      position: absolute;
      right: 2%;
      top: 2%;
      width: 45px;
      height: 45px;
      img {
        max-width: 100%;
      }
    }
    .cont-circle {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 78px;
      left: 10px;
      z-index: 99;
      .cont {
        position: absolute;
        left: 5px;
        top: 4px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
        padding: 13px;
        padding-top: 8px;
        border: 3px solid rgb(175, 175, 175);
        border-radius: 50%;
        width: 90%;
        height: 90%;
        .ico {
          width: 13px;
        }
        .count {
          display: inline-block;
          width: 100%;
          text-align: center;
          border-bottom: 1px solid $border-gray;
          font-size: 12px;
          font-weight: 600;
          padding-bottom: 3px;
          margin-top: 3px;
        }
        p {
          font-size: 8px;
          margin-bottom: 0;
          text-align: center;
          margin-top: 3px;
          font-weight: 700;
        }
      }
      &.c1 {
        left: 1%;
        top: 12%;
      }
      .tail-ico {
        position: absolute;
        left: 44px;
        top: 79px;
        width: 37px;
      }
      &.c2 {
        left: 36%;
        top: 1%;
        .tail-ico {
          left: 36px;
          top: 70px;
          width: 42px;
        }
      }
      &.c3 {
        left: auto;
        right: 1%;
        top: 108px;
        .tail-ico {
          left: 12px;
          top: 82px;
        }
      }
      &.c4 {
        top: 69%;
        .tail-ico {
          left: 44px;
          top: -41px;
          width: 53px;
        }
      }
      &.c5 {
        top: 70%;
        left: auto;
        right: 1%;
        .tail-ico {
          left: -14px;
          top: -51px;
          width: 62px;
        }
      }
    }
    .big-circle {
      width: 250px;
      height: 250px;
      // margin: 126px auto 0;
      justify-self: center;
      align-self: center;
      // position: relative;
      .cont {
        width: 151px;
        height: 151px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-flow: column;
        .yellow {
          font-size: 9px;
          color: $yellow;
          text-transform: uppercase;
          font-weight: 600;
        }
        h5 {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          margin: 0;
          img {
            margin-right: 3px;
          }
        }
        hr {
          border-top: 2px solid $dark;
          width: 77%;
          margin: 3px 0;
          opacity: 0.4;
        }
        .chart-img {
          width: 75%;
          margin: 3px 0;
        }
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .bg-perf {
      height: 560px;
    }
    .per-cont {
      transform: scale(0.9);
      -moz-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      .btn-empty {
        right: -20px;
        top: -30px;
      }
    }
  }
  @media only screen and (max-width: 359px) {
    .per-cont {
      .btn-empty {
        right: 20px;
        top: -20px;
      }
    }
  }
}

.btn-clip-wrap {
  .btn-clip {
    width: 100%;
    clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
    -moz-clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    span {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 4px;
      &.txt-ico {
        top: 0;
        span {
          top: 0;
          color: $gray1;
        }
      }
      .ico {
        width: 22px;
        margin-right: 5px;
      }
      .chat-count {
        position: absolute;
        right: -22px;
        top: -6px;
        span {
          position: absolute;
          background: #06d81e;
          background: linear-gradient(135deg, #06d81e 0%, #27b43d 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06d81e', endColorstr='#27b43d',GradientType=1 );
          color: $white;
          width: 20px;
          height: 20px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          right: -15px;
          bottom: -13px;
          -webkit-box-shadow: 0 0 5px #fff;
          -moz-box-shadow: 0 0 5px #fff;
          box-shadow: 0 0 5px #fff;
        }
      }
    }
    &.btn-white {
      background: #b7b7b7;
      border: none;
      & * {
        opacity: 0.25;
      }
      &:before {
        content: '';
        background: #f5f5f5;
        clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
        -moz-clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
        -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 98%, 0 100%, 2% 18%);
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        z-index: -9;
      }
    }
  }
}

.product-list {
  .edit-btn {
    position: absolute;
  }
  .img-wrap {
    .edit-btn {
      top: 10px;
      right: 20px;
    }
  }
  .logo-sec {
    position: relative;
  }
  .edit-btn {
    &.logo-edit {
      left: 70px;
      top: 70px;
    }
  }
  h4 {
    .edit-btn {
      right: 12px;
      position: relative;
    }
  }
  .motto {
    position: relative;
    .edit-btn {
      right: 0;
      bottom: 0;
    }
  }
  .shop-carousel {
    .edit-btn {
      z-index: 99;
      top: auto;
      bottom: 10px;
      right: 10px;
    }
  }
}

// Performace Modal Style
.performace-modal {
  display: inline-block;
  width: 90%;
  margin: 50px auto 0;
  h5 {
    font-size: 22px;
    color: $dark;
    text-align: center;
    line-height: 27px;
    margin-bottom: 20px;
  }
  .it-work {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 40px;
    .thumb-sec {
      width: 40px;
      display: flex;
      flex-flow: column;
      .thumb {
        width: 39px;
        margin: 2.5px 0;
        filter: grayscale(1);
        -moz-filter: grayscale(1);
        -webkit-filter: grayscale(1);
        &.active {
          filter: grayscale(0);
          box-shadow: 0 0 6px $yellow;
          -moz-box-shadow: 0 0 6px $yellow;
          -webkit-box-shadow: 0 0 6px $yellow;
        }
      }
    }
    .big-circle {
      width: 106px;
      height: 108px;
      margin: 0 0 0 11px;
      position: relative;
      .cont {
        width: 110px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-flow: column;
        .yellow {
          font-size: 6px;
          color: $yellow;
          text-transform: uppercase;
          font-weight: 600;
          &.dark {
            color: $dark;
          }
        }
        h5 {
          display: flex;
          align-items: center;
          font-size: 11px;
          font-weight: 700;
          margin: 0;
          line-height: 13px;
          img {
            margin-right: 3px;
            width: 11px;
          }
        }
        hr {
          border-top: 2px solid $dark;
          width: 77%;
          margin: 2px 0;
          opacity: 0.4;
        }
        .chart-img {
          width: 56px;
          margin: 0;
        }
        .tail-ico {
          width: 76px;
          position: absolute;
          top: 91px;
          left: -18px;
        }
      }
    }
    .btn-txt {
      position: absolute;
      bottom: 0;
      align-self: end;
      right: 50px;
      padding: 0;
      font-size: 12px;
      font-weight: 600;
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
      -moz-text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
      -webkit-text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    }
  }
  p {
    font-size: 13px;
  }
  .btn-close-x {
    position: relative;
    margin: 30px auto 10px;
    display: block;
    width: 70px;
    height: 70px;
  }
  &.btm-fix-btn {
    padding-bottom: 70px;
    .btn-close-x {
      position: absolute;
      margin: 0;
      left: calc(50% - 35px);
      bottom: 0;
    }
  }
}

// Election Snippet Style
.election-snippet {
  width: 100%;
  height: 170px;
  background: $white;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  position: relative;
  margin-bottom: 15px;
  .img-wrap {
    width: 170px;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .chat-count {
    position: absolute;
    top: -1px;
    right: 22px;
    span {
      position: absolute;
      background: #06d81e;
      background: linear-gradient(135deg, #06d81e 0%, #27b43d 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06d81e', endColorstr='#27b43d',GradientType=1 );
      color: #fff;
      width: 20px;
      height: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-radius: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      right: -15px;
      bottom: -13px;
      box-shadow: 0 0 5px $white;
    }
  }
  .cont {
    width: calc(100% - 170px);
    padding: 15px;
    h6 {
      display: inline-block;
      width: 100%;
      font-size: 15px;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 4px;
      font-size: 13px;
      color: $gray1;
      display: flex;
      align-items: center;
      &.btm-tag {
        padding-top: 4px;
        color: $dark;
        justify-content: end;
        .btn {
          font-size: 11px;
          padding: 1px 16px;
          text-transform: uppercase;
          font-weight: 600;
          &.btn-gray {
            background: #b7b7b7;
            color: $dark;
          }
        }
      }
      .ico {
        width: 18px;
      }
      span {
        width: calc(100% - 18px);
        padding-left: 8px;
      }
    }
  }
  .status {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .bg {
      width: 100%;
      vertical-align: top;
    }
    span {
      display: flex;
      align-items: center;
      color: $white;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
      position: absolute;
      top: 1px;
      left: 0;
      padding: 2px 10px;
      &.dark {
        color: $dark;
      }
    }
  }
  .radio-form {
    position: absolute;
    bottom: 10px;
    left: 10px;
    .form-check {
      min-height: unset;
      .form-check-input {
        width: 20px;
        height: 20px;
        margin-top: 0;
        border: 2px solid #06d81e;
        &:checked {
          border: 2px solid $dark;
          background: $white url('../images/check-active-ico.svg') no-repeat center center;
        }
      }
    }
  }
  @media only screen and (max-width: 399px) {
    height: 120px;
    .img-wrap {
      width: 120px;
      height: 120px;
    }
    .status {
      span {
        top: 0;
        font-size: 11px;
      }
    }
    .cont {
      width: calc(100% - 120px);
      padding: 10px;
      h6 {
        font-size: 13px;
        margin-bottom: 3px;
      }
      p {
        font-size: 10px;
        margin-bottom: 2px;
        &.btm-tag {
          padding-top: 0;
          .btn {
            font-size: 9px;
            margin-top: -1px;
          }
        }
        .ico {
          width: 13px;
        }
      }
    }
  }
}

// Preview Sec Style
.preview-sec {
  display: inline-block;
  padding: 15px;
  width: calc(100% - 30px);
  margin: 0 15px;
  background: $light-gray;
  @media only screen and (max-width: 379px) {
    padding: 7px;
    width: calc(100% - 15px);
    margin: 0 7.5px;
    .p-row {
      p {
        font-size: 11px !important;
        a {
          font-size: 11px !important;
        }
      }
    }
  }
}

// Election Confirm Style
.close-cont {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  .btn-close-x {
    position: static;
  }
  p {
    margin: 0;
    width: calc(100% - 80px);
    padding-right: 20px;
    font-size: 12px;
    font-weight: 500;
    color: $gray1;
  }
}
.rect-pop {
  width: 340px;
  .text-success {
    color: #06d81e !important;
  }
  .cont {
    top: 19px;
    left: 0px;
    padding: 10px 32px 0 60px;
    width: 100%;
    .finger-ico {
      width: 46px;
      margin: 10px auto 20px;
      display: block;
    }
    p {
      font-size: 11px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 12px;
    }
  }
  @media only screen and (max-width: 359px) {
    width: 300px;
    .cont {
      .finger-ico {
        margin-bottom: 10px;
        width: 40px;
      }
      p {
        margin-bottom: 10px;
        font-size: 10px;
      }
    }
  }
}

#elec-confirm1-modal {
  @media only screen and (max-width: 360px) {
    .alert-bubble-img {
      .cont {
        transform: scale(0.75);
        top: 67px;
      }
    }
  }
}

.add-new-elec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: $white;
  border: 2px dashed $dark;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
  width: 100%;
  height: 170px;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
  .ico {
    width: 45px;
    margin-bottom: 10px;
  }
}

// Footer Button Style
.btn-dual {
  background: #b7b7b7;
  display: flex;
  width: 100%;
  .btn {
    width: 50%;
    height: 50px;
    border-radius: 0;
    &:first-child {
      border-right: 2px solid $gray1;
    }
  }
}

// Candidate Performance Style
.candid-per {
  .bg-perf {
    height: 500px;
  }
  .per-cont {
    top: 0;
    .big-circle {
      margin-top: 100px;
    }
    .cont-circle {
      top: 48px;
      &.c3 {
        top: 79px;
      }
      &.c4 {
        top: 335px;
      }
      &.c5 {
        top: 352px;
        right: 10px;
      }
    }
  }
}

.bottom-input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  .type-area {
    position: relative;
    bottom: 8px;
    left: 8px;
    padding: 0;
    width: calc(100% - 16px);
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 3% 8%);
    background: $gray1;
    height: 54px;
    .type-inside {
      clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 3% 8%);
      background: $white;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
      display: flex;
      align-items: center;
      .btn-emoti {
        margin-left: 5px;
      }
      .form-control {
        border: none;
        width: calc(100% - 58px);
        font-size: 14px;
      }
    }
  }
  .btn-close-x {
    position: static;
  }
}

.edge-fade {
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 19%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 19%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 19%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 38px;
  font-size: 12px;
  color: $dark;
  span {
    font-size: 14px;
    color: $gray1;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: 375px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ship-address {
  background: $yellow;
  width: 260px;
  display: flex;
  margin: 0 auto;
  padding: 10px;
  flex-flow: column;
  text-align: left;
  h6 {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid $gray1;
    padding-bottom: 8px;
  }
  p {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
    padding: 0 10px;
  }
}

.free-gift-info {
  .register-form {
    .form-group {
      &.bg {
        min-height: unset;
      }
      .form-control {
        width: 100%;
        padding: 10px 12px 10px 25px;
        border: none;
        font-size: 14px;
      }
      .form-check {
        .form-check-input {
          border: 2px solid $dark;
          background-color: $white;
          background-blend-mode: difference;
          background-size: 16px;
        }
      }
    }
  }
}

.pdf-modal {
  .btn-close-x {
    position: static;
    align-self: flex-start;
    margin-bottom: 10px;
  }
  .thumb-img {
    width: 87%;
    margin: 0 auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
    img {
      width: 100%;
    }
  }
  p {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: $dark;
    padding: 0 20px;
  }
}

.total-amt {
  background: -moz-linear-gradient(
    left,
    rgba(8, 7, 8, 0) 0%,
    rgba(8, 7, 8, 1) 20%,
    rgba(8, 7, 8, 1) 81%,
    rgba(8, 7, 8, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(8, 7, 8, 0) 0%,
    rgba(8, 7, 8, 1) 20%,
    rgba(8, 7, 8, 1) 81%,
    rgba(8, 7, 8, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(8, 7, 8, 0) 0%,
    rgba(8, 7, 8, 1) 20%,
    rgba(8, 7, 8, 1) 81%,
    rgba(8, 7, 8, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00080708', endColorstr='#00080708',GradientType=1 );
  color: $white;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 15px;
  margin-bottom: 40px;
  p {
    margin-bottom: 0;
    strong {
      display: block;
      width: 100%;
      font-weight: 600;
    }
  }
}

.error-msg {
  color: red !important;
}

.error-warning {
  color: black !important;
}

.vote-progress {
  .progress-container {
    background-image: url('/images/progress-bar-bg.svg');
    background-size: cover;

    background-repeat: no-repeat;
    width: 100%;
    height: 22px;
    position: relative;

    .progress {
      position: relative;
      height: 23px;
      background-color: transparent;

      transition: width 0.4s linear;
      top: -1px;
    }

    .percentage {
      background-color: #000;
      border-radius: 25px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      color: #ffd306;
      font-weight: bolder;
      font-size: 12px;
      padding: 4px;
      position: absolute;
      left: 25%;
      top: 1px;
      width: 22px;
      height: 22px;
    }

    // .percentage::after {
    //   background-color: #6F39B0;
    //   content: '';
    //   position: absolute;
    //   top: -5px;
    //   left: 50%;
    //   transform: translateX(-50%) rotate(45deg);
    //   height: 10px;
    //   width: 10px;
    //   z-index: -1;
    // }
  }
  .percentage-text {
    color: grey;
    font-size: 10px;
    padding: 4px;
    position: absolute;
    right: 69px;
    top: 3px;
    width: 20px;
    height: 20px;
  }
  .percentage-grey {
    background-color: #a09e9e;
    border-radius: 25px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    position: absolute;
    right: -8px;
    top: 1px;
    width: 23px;
    height: 23px;
  }
}

.map-business {
  top: -146px !important;
  right: -34px !important;
}
.my-page-map {
  right: 47px !important;
  top: 140px !important;
}
.map-location-contain {
  position: absolute;
  width: 200px;
  right: -85px;
  top: -86px;

  .map-location-wrap {
    position: relative;
    background-color: $black;
    border-radius: 6px;
    padding: 15px 15px;

    .location-logo {
      position: absolute;
      top: -20px;
      left: 10px;
      border: 2px solid #080708;
      border-radius: 50%;

      .location-logo-img {
        border-radius: 50px;
        position: relative;
        border: 4px solid #fff;
        object-fit: cover;
        width: 50px;
        height: 50px;
      }

      .location-logo-img:before {
        content: ' ';
        position: absolute;
        z-index: 1;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border: 5px solid #ffea00;
      }
    }

    .location-content {
      h4 {
        color: $white;
        font-size: 12px;
        text-align: center;
        padding-left: 25px;
        text-transform: uppercase;
      }

      p {
        color: $white;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 0px;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .location-marker {
      position: absolute;
      bottom: -56px;
      left: 65%;
    }
  }
}

.blur-map {
  filter: blur(4px);
}

.image-view-container {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 1) !important;
    border: 0px solid black !important;
    z-index: 10000000;

    /* opacity: 10%; */
  }
}

.scanner-qr-code {
  height: 100vh !important;
  section {
    height: 100% !important;
    div {
      height: 100% !important;
      padding-top: 0% !important;

      video {
        position: relative !important;
        height: 100% !important;
        width: auto !important;
      }
    }
    .btn-close-x {
      position: absolute;
      bottom: 150px;
      left: 40%;
      width: 80px;
      height: 80px;
      z-index: 100;
    }
  }
  .info-text {
    position: absolute;
    bottom: 105px;
    left: 0;
    width: 100%;
    padding: 0 20px 20px;
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 4px #fff;
  }
}

.wrapper {
  display: inline-block;
  position: relative;
  .btnimg {
    // background: red;
    // pointer-events: none;
    // width: 250px;
    // height: 90px;
    opacity: 1;
    // line-height: 90px;
    text-align: center;
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.wrapper-img {
  position: absolute !important;
  width: 150px;
  height: 150px;
}

.yes-no-btn {
  display: flex;
  justify-content: center;
  column-gap: 50px;
  .circle-btn {
    button {
      background-color: #fff;
      width: 70px;
      height: 70px;
      border-radius: 50px;
      border: 1px solid #080708;
      margin-bottom: 6px;

      &.no {
        background-color: #fdd519;
      }
    }
    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
.map-sec-category {
  width: 100%;
  height: 150px;
}

.see-detail-btn {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 10px;
  .circle-btn {
    button {
      background-color: #b7b7b7;
      width: 130px;
      height: 30px;
      border-radius: 50px;
      border: 1px solid #b7b7b7;
      margin-bottom: 6px;
      color: black;
      font-weight: bold;
      font-size: 12px;

      &.no {
        background-color: black;
        border: 1px solid black;
        color: #ffff !important;
      }
    }
  }
}
// .business-map {
//   .mapboxgl-marker {
//     background-image: url('/images/businessPin.png');

//     background-repeat: no-repeat;
//     height: 30px;
//     width: 24px;
//   }
// }

.user-icon-marker {
  top: -15px !important;
  left: -15px !important;
}

.election-pin-marker {
  top: -28.41px !important;
  left: -10px !important;
}

// .business-pin-marker {
//   top: -31px !important;
//   left: -10px !important;
// }

.mapbtn-address {
  position: absolute;
  bottom: -9px;
  right: 7px;
}

.fb {
  background: #2d509b;
  color: #fff;
  margin-bottom: 15px;
  width: 100%;
  // margin: 10px;
}
.google {
  background: #fff;
  border: 1px solid #c9c9c9;
  width: 100%;
}

.rotation {
  transform: rotate(270deg) !important;
}
@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.circle-container {
  @include on-circle($item-count: 4, $circle-size: 10em, $item-size: 3em);
  margin: 1em auto 0;
  border: solid 5px tomato;

  img {
    display: block;
    max-width: 100%;
    border-radius: 50%;
    filter: grayscale(100%);
    border: solid 5px tomato;
    transition: 0.15s;

    &:hover,
    &:active {
      filter: grayscale(0);
    }
  }
}
.margin-category {
  margin-left: 20px;
}
